import React, { useState } from 'react';
import Grid from '../blocks/Grid';

import ButtonCTA from '../components/ButtonCTA';
import LoadingAnimation from '../components/LoadingAnimation';
import PressVideo from "./PressVideo";
import NewsTile from '../components/NewsTile';
import NewsTileAlt from '../components/NewsTileAlt';
import {Helmet} from "react-helmet";


// ADD VIDEO ON TOP

const Newsroom = () => {
	const [news, setNews] = useState(null)
	const [press, setPress] = useState(null)
	const [pNews, setPNews] = useState([])
	const [pPress, setPPress] = useState([])
	const [errors, setErrors] = useState({ news: null, press: null })
	!errors.news && !news && fetch('/api/news').then(resp => resp.json()
		.then(x => { setNews(x); paginate(x, pNews, setPNews, 6) }))
		.catch(err => setErrors({ news: err, press: errors.press }))
	!errors.press && !press && fetch('/api/press-releases').then(resp => resp.json()
		.then(x => { setPress(x); paginate(x, pPress, setPPress, 6) }))
		.catch(err => setErrors({ news: errors.news, press: err }))

	const paginate = (collection, currentPagination, setPagination, limit) => {
		if (!canLoadMore(collection, currentPagination)) return
		setPagination([...collection].splice(0, limit + currentPagination.length))
	}

	const canLoadMore = (collection, currentPagination) => !(collection.length === currentPagination.length)

	const articleItems = items => {
		return items.map((x, i) => {
			return <NewsTileAlt key={x._id} className={`mr0i`} news={x}></NewsTileAlt>
		})
	}
	const pressItems = items => {
		return items.map((x, i) => {
			return <NewsTile key={x._id} className={`mr0i`} news={x}></NewsTile>
		})
	}
	return <div className="page-newsroom">
		<Helmet>
			<title>Air Protein | Press and Media</title>
			<meta name="description" content=""></meta>
			<meta name="keywords" content=""></meta>
		</Helmet>
		<div
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/newsroom/clouds.jpeg)',
				backgroundSize: 'cover',
				backgroundPosition: 'center bottom'
			}}>
			<Grid>


          



	  			<div class="w100% mb-items-lg p1.5rem splitRow">
					
					<div className='split50'>
					<div class="fz4rem fz2rem@md c-white topSpace">Press</div>
				<div className="df fxdrc@md aife aic@md jcfs w100% p1.5rem pt2rem pb2rem">
					<div className="w80%@md mb-items-lg">
						<div className="w100% df fxdrc jcc aifs mb-items-lg">
							<div className="fz2.25rem fz1.25rem@md c-white">All available logos, photography and assets are available in our ready-to-download Media Kit.</div>
							<div className='hideOnMob'>
								<ButtonCTA onClick={_ => window.open('https://d172nc0scfm542.cloudfront.net/downloads/MediaKit.zip', '_blank')} className="tr fz0.875rem" value="DOWNLOAD MEDIA KIT" style={{ width: "13.5rem" }} />
							</div>
						</div>
					</div>

				</div>						
					</div>
					<div className="split50">
					<PressVideo />
          </div>
</div>




				<div className="df fxdrc aife aic@md jcsa w100% pt2rem pb2rem">
					<div className="w100% mb-items-lg p1.5rem">
						<div className="fz4rem fz2rem@md c-white">In the News</div>
					</div>
					<div className="articles df fxww fxdrc@md jcc@md w100% mb-items-lg">
						{news && articleItems(pNews)}
						{!news && !errors.news &&
							<LoadingAnimation />
						}
						{errors.news &&
							<p>Error loading content</p>
						}
					</div>
				</div>
				<div className="df jcc pb4rem">
					<div>
						{news && canLoadMore(news, pNews) &&
							<ButtonCTA onClick={_ => paginate(news, pNews, setPNews, 6)} className="tr fz0.875rem" value="LOAD MORE NEWS" style={{ width: "13.125rem" }} />
						}
					</div>
				</div>
			</Grid>
		</div>
		<Grid>
			<div className="df fxdrc aife aic@md jcsa w100% pt2rem pb2rem">
				<div className="w100% mb-items-lg p1.5rem">
					<div className="fz4rem fz2rem@md c-crusta">Press Releases</div>
				</div>
				<div className="articles df fxww fxdrc@md jcc@md w100% mb-items-lg">
					{press && pressItems(pPress)}
					{!press && !errors.press &&
						<LoadingAnimation />
					}
					{errors.press &&
						<p>Error loading content</p>
					}
				</div>
			</div>
			<div className="df jcc pb4rem">
				<div>
					{press && canLoadMore(press, pPress) &&
						<ButtonCTA onClick={_ => paginate(press, pPress, setPPress, 6)} className="tr fz0.875rem" value="LOAD MORE ARTICLES" style={{ width: "13.125rem" }} />
					}
				</div>
			</div>
		</Grid>
	</div>
}

export default Newsroom;
