import React from 'react'

const MenuIcon = props => {
    return <svg {...props} style={{cursor:'pointer'}} viewBox='0 0 10 8' width='40'>
        <path d='M1 1h8M1 4h 8M1 7h8'
            stroke='#24484B'
            strokeWidth='1'
            strokeLinecap='round' />
    </svg>
}

export default MenuIcon