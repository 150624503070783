import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';

const Section6 = () => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    ScrollTrigger.matchMedia({
      '(min-width: 801px)': () => {
        animations.push(
          gsap
            .timeline()
            .to(element.querySelector('.js-content-container'), {
              scrollTrigger: {
                trigger: element.querySelector(
                  '.js-background-shift-container'
                ),
                start: `top top`,
                end: 'bottom bottom',
                scrub: true,
                pin: true,
                pinSpacing: false,
              },
            })
            .to(element.querySelector('.js-content-container'), {
              scrollTrigger: {
                trigger: element.querySelector(
                  '.js-background-shift-container'
                ),
                start: `top top`,
                end: `${window.innerHeight * 3.8}px bottom`,
                scrub: 0.5,
              },
              transform:
                window.innerWidth > 800
                  ? 'translateX(-50vw)'
                  : window.innerWidth > 600
                  ? 'translateX(-90vw)'
                  : 'translateX(-120vw)',
            })
        );
      },
    });

    return function cleanup() {
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  return (
    <div className='section-6 posr z1 oh' ref={ref}>
      <div className='js-section-6 posr z1'>
        <div className='side-scrolling-section js-background-shift-container w100vw h400vh h-auto@md'>
          <div
            className='js-fixed-background posa h100vh w100vw t0 l0 h100%@md'
            style={{
              backgroundImage:
                'url(https://d172nc0scfm542.cloudfront.net/home/background-clouds-3.jpg)',
              backgroundPosition: '0% 100%',
              backgroundSize: 'cover',
            }}
          ></div>
          <div className='js-content-container posr z1 df aic jcc h100vh w150vw trf-trx0 fxdrc@md h-auto@md w70vw@md center-hori@md w90vw@sm'>
            <div className='df jcfs w30vw mr5vw w80%@md mr0@md mb10vh@md pt10vh@md w100%@sm'>
              <div>
                <h2 className=' fz-clamp(32,3.5vw,56) mih0vh c-white mb4vh'>
                  Making Meat
                  <br />
                  from Air
                </h2>
                <p className=' fz1.2vw mb4vh fz20@lgx'>
                  Air meat is crafted through a process similar to how beer and
                  yogurt are made and can be produced exponentially faster than
                  traditional meat production. Explore how we turn air into the
                  future of meat.
                </p>
                <Link
                  className='dib tdn c-plantation bd3-s-plantation bdrs25 pl75 pr75 pt10 pb10 posr oh fz1vw fz16@lgx pl20@md pr20@md'
                  to='/making-air-meat'
                >
                  <div className='bgc-white op.3 posa h100% w100% center'></div>
                  <span className='posr'>MAKING AIR MEAT</span>
                </Link>
              </div>
            </div>

            <div className='tac w40vw mr10vw w100%@md mr0@md mb10vh@md'>
              <img
                className='w100% maw650'
                src='https://d172nc0scfm542.cloudfront.net/home/air-food-3.png'
              />
            </div>

            <div className='df jcfe w30vw w80%@md mr0@md mb10vh@md w100%@sm'>
              <div>
                <h2 className=' fz-clamp(32,3.5vw,56) mih0vh c-white mb4vh'>
                  Inspired&nbsp;by&nbsp;NASA.
                  <br />
                  Designed by
                  <br />
                  Air Protein.
                </h2>
                <p className=' fz1.2vw mb4vh fz20@lgx'>
                  Meet the innovators that turned a space-age NASA idea into a
                  lofty dream and learn the story behind Air Protein.
                </p>
                <Link
                  className='dib tdn c-plantation bd3-s-plantation bdrs25 pl75 pr75 pt10 pb10 posr oh fz1vw fz16@lgx pl20@md pr20@md'
                  to='/our-story'
                >
                  <div className='bgc-white op.3 posa h100% w100% center'></div>
                  <span className='posr'>OUR STORY</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
