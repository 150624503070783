import React from "react"
import Grid from "../blocks/Grid"
import {Helmet} from "react-helmet";


const Privacy = _ => {
    return <div className="privacy-page">
        <Helmet>
            <title>Air Protein | Privacy Statements</title>
            <meta name="description" content=""></meta>
            <meta name="keywords" content=""></meta>
        </Helmet>
        <Grid className="pt6rem mb-items-md">
        <p><a name="_Hlk58595426"><b>Air Protein, Inc. </b></a><b>-
Privacy Notice</b></p>

<p>Effective: Oct 8 2022</p>

<p><b>Please read this Privacy Notice carefully</b>. It provides important information about your
personal information and tells you about your rights under applicable privacy
laws. If you have any questions, comments, or concerns regarding this Privacy
Notice and/or our data practices, please <a href="#_Contact_Us">contact us</a>.</p>

<p>If you're a
resident of California or Nevada, you should read this policy and the
applicable sections below for residents of <a
href="#_International_Transfers">California</a> and <a
href="#_Nevada_Privacy_Rights">Nevada</a>.</p>

<p>By accessing or using THE Site (or any part thereof), or submitting
information in connection with your use of the Site (or any part thereof), you
acknowledge and agree that you have read this Privacy Notice.</p>

<p><b><u>Table of Contents</u></b></p>

<p>1. <a href="#_Introduction">Introduction</a></p>

<p>2. <a href="#_Introduction">Changes to this Privacy Notice</a><a href="#_Information_we_Collect"></a></p>

<p>3. <a href="#_Information_we_Collect">Information We Collect</a></p>

<p>4. <a href="#_Children’s_Privacy">Children’s Privacy</a></p>

<p>5. <a href="#_Using_Personal_Information">Sensitive Data</a></p>

<p>6. <a href="#_Using_Personal_Information">How Air Protein Uses Personal Information</a></p>

<p>7. <a href="#_Sharing_Personal_Information">Sharing Personal Information</a></p>

<p>8. <a href="#_Cookies_and_Tracking">Marketing Communications</a></p>

<p>9. <a href="#_Your_Choices">Your Choices</a></p>

<p>10. <a href="#_Third_Party_Social">Third Party Social Plug-Ins</a></p>

<p>11. <a href="#_App_Stores_and">External Websites</a></p>

<p>12. <a href="#_Data_Retention">Data Retention</a></p>

<p>13. <a href="#_Data_Security_and">Data Security and Protection</a></p>

<p>14. <a href="#_International_Transfers">California
Privacy Rights – Shine the Light</a></p>

<p>15. <a href="#_Nevada_Privacy_Rights">Nevada
Privacy Rights</a></p>

<p>16. <a href="#_International_Transfers">International Transfers</a></p>

<p>17. <a href="#_Contact_Us">How to Contact Us</a></p>

<h1><a name="_Introduction"></a><a name="_Changes_to_this"></a><b>1. </b><b>Introduction</b></h1>

<p>Air Protein, Inc. (“<b>Air Protein</b>”, “<b>Company</b>”, “<b>we</b>”, “<b>our</b>”
or “<b>us</b>”) owns and operates ’s corporate-facing website located at <a target="_blank"
href="https://d.docs.live.net/704a663e6810c5a9/Paradigm%20Counsel/Air%20Protein%20-%20unsynced/www.airprotein.com">www.airprotein.com</a> (the “<b>Site</b>”).
This Privacy Policy
applies to the Site and any services or features that we provide or make available
directly in connection with the use of the Site (the “<b>Services</b>”).</p>

<h1><b>2. </b><b>Changes to this Privacy Notice</b></h1>

<p>Company reserves
the right to update or modify this Privacy Notice at any time. Except for
material changes as described below in this Section, all updates
and modifications to these documents will be effective from the day they are
posted on the Site at <a target="_blank" href="http://www.airprotein.com/privacy">www.airprotein.com/privacy</a>. <a
name="_Hlk12267176">If </a>we make any material changes to this Privacy Notice,
we will notify you of these changes by sending a notification to the email
address we have on file for you, and may also post a prominent notice of any
such changes on the Site.
It is your responsibility to regularly visit and review this Privacy Notice. If
you do not agree to any updates or modifications to the Privacy Notice, cease
all use of the Site and Services. Your continued use of the Site and/or
Services after we have posted the updated Privacy Notice, or, in the event of
material changes, ten (10) days following the date we first notified you of
such material changes via email or the date we posted the notice of such
changes on the Site, as applicable, signifies to us that you acknowledge and
agree to be bound by the revised Privacy Notice.</p>

<h1><a name="_Information_we_Collect"></a><a
name="_Information_we_Collect_1"></a><b>3. </b><b>Information We Collect</b></h1>

<p>How and what
personal information we collect in connection with the use of the Site and
Services depends on how and why you use the Site and Services.</p>

<p>The term “personal
information” or “personal data” means any information about an individual from
which that person may be identified. For example, it may include a person’s
name, telephone number, email address, or payment information, and in some
jurisdictions, IP address. It does not include data from which the identity of
an individual has been definitively removed along with any identifiers
connected to such individual (also known as anonymous or anonymized data).</p>

<p>Generally, Company collects personal
information when you:</p>

<p>· Visit and/or browse the Site,</p>

<p>· Interact with and/or use other services provided by us as part of or in connection with
your use of the Site and/or Services,</p>

<p>· Apply for a job through the Site,</p>

<p>· Sign up for our marketing communications and/or newsletters, and/or</p>

<p>· Communicate with us directly through the Site and/or Services.</p>

<p>In some instances, we collect information directly from you as explained in the Section <a
href="#_Information_Collected_Indirectly_1">Information
Provided Directly to Air Protein</a>, and in in certain circumstances, you will not
provide any information to us directly, in which case we automatically collect
certain information as explained <a
href="#_Information_Collected_Indirectly_2">Information Collected Indirectly</a>.</p>

<h2><a name="_Information_Collected_Indirectly_1"></a><a
name="_Information_Provided_by"></a>a. Information Provided Directly to <a
name="_Payment_and_Transaction"></a><a name="_Identity_Verification_Information"></a><a
name="_Information_From_Grantseekers"></a><a name="_Targeting_&amp;_Advertising"></a><a
name="_Information_Collected_from"></a>Air Protein</h2>

<p>Company collects the following types of information directly from you:</p>

<p><a name="_Information_from_Customers"></a>· <b><i>General Contact Information</i></b>: If you sign up for our marketing communications, and/or submit a request to contact us through our online forms, we will collect general contact information such as your name (first and last) and email address. In addition, in connection with the above scenarios, we may also ask for your place of work and areas of interest with respect to Company products and/or services.</p>

<p>· <b><i>Other Information</i></b><i>: </i>In addition to the above information, if you fill out forms, answer surveys, apply for employment, or contact us (such as your feedback, requests for support, or other communications with us) we collect the information, data, content, documents and/or materials you provide to us in connection with the foregoing.</p>

<p>Please be advised that we may ask you to update your information from time to time in order to keep it accurate.</p>

<h2><a name="_Information_Collected_Indirectly_2"></a>b. Information Collected Indirectly</h2>

<h3>i. <i>Usage Information</i></h3>

<p>We, or our authorized third-party service providers, automatically collect technical
and/or analytics information about how you use and/or interact with our Site
and/or Services (collectively, “<b>Usage Information</b>”).</p>

<p>Usage Information
that we collect consists of information about our users’ interactions with the Site
and/or Services. Usage Information is collected while you are using and/or
accessing the Site and/or Services, and may include information about the device
and/or platform (e.g., iOS or Android), and/or the web browser, used by you to
interact with and/or access the Site, your IP address, access dates and times,
information about your approximate location (as determined through your IP
address), device information, device event information, crash data, and log files
and data.</p>

<p>Usage Information
allows Company to understand how users are interacting with and using the Site
and/or Services (which may include administrative and support communications
with us), and other actions taken in connection with the use of the Site and/or
Services. We use this information for our internal purposes, specifically to
operate, maintain, secure and improve the Site and Services.</p>

<p>Typically, all
Usage Information is collected in anonymous form and does not identify you
personally. To the extent any Usage Information is linked or tied to personally
identifiable information, such information is deemed “personal data” and we
will use it and protect it in accordance with this Privacy Notice.</p>

<p>We may also use
this information to provide you with notifications, recommendations, and
information about specific features of the Services and/or additional products,
services, or features we believe may be of interest to you.</p>

<h3><i>ii. </i><i>Cookies and Similar Technologies</i></h3>

<p>We or authorized
third parties may collect certain information (including, without limitation,
Usage Information) by automated means using technologies such as cookies, web
beacons, embedded scripts, pixels, browser analysis tools, server logs, and
mobile identifiers, and similar technologies that are deployed, and typically
stored on your device and have a unique identifier, when you visit a website,
when you open an email or click on links or images within an email, and/or when
you interact with online advertisements.</p>

<p>These technologies
may be used for many different purposes. For example, they can be used to show
that you have previously visited and/or used a feature of the Site and to
identify which parts of the Site you might be most interested in, or used to
improve your online experience by storing your preferences during your visit
and/or use of the Site. These technologies can also be used for security, fraud
detection and authentication purposes.</p>

<p>In many cases, the
information we collect using these technologies is only used in a
non-identifiable manner without reference to personal information. For example,
we may use information we collect to better understand website traffic patterns
and to optimize user experience. In other cases, we associate the information
we collect using cookies and other technology with your personal information.</p>

<p>Company does not
directly track users across third party websites; however, certain third
partner advertising partners may track you across third party websites as
described <a href="#_Targeting_&amp;_Advertising">below</a>.</p>

<p>For information regarding how you can manage your cookie preferences, please see <a
href="#_How_to_Manage">How to Manage Cookie Preferences</a> below.</p>

<h3><i>iii. </i><i>Third Party Analytics Providers, Tools and Advertising
Partners</i></h3>

<h3>We use certain third party analytics tools
and advertising partners, including, without limitation, Google, to help us
gather certain Usage Information and to help us place advertisements as
described above. For more information on how these technologies are used by
Google, please refer to the policies linked below of the applicable third party
analytics tools and/or advertising partners:</h3>

<p>· Google Products (which may include Google Analytics &amp; Google Firebase):</p>

<p>· <a target="_blank" href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a></p>

<p>· <a target="_blank" href="https://firebase.google.com/support/privacy/dpo">https://firebase.google.com/support/privacy/dpo</a></p>

<p>&nbsp;</p>

<h3><a name="_How_to_Manage"></a><i>iv. </i><i>How
to Manage Cookie Preferences</i></h3>

<h3>Where required by applicable law, we will
obtain your consent prior to setting cookies (except for strictly necessary
cookies). Most web and mobile device browsers automatically accept cookies, but
if you prefer, you can change your browser to prevent that or to notify you
each time a cookie is set.</h3>

<h3>In order to manage your cookie
preferences, you may alter the cookie settings in your browser settings at any
time. You may accept all, or certain, cookies. If you do disable our cookies in
your browser settings, you may find that certain features and/or functionality
of the Site and/or Services do not work.</h3>

<h3>To learn more about cookies, including how
to manage cookies and opt-out of cookies being placed on your device, please
visit <a target="_blank" href="http://www.allaboutcookies.org/manage-cookies/index.html">http://www.allaboutcookies.org/manage-cookies/index.html</a>.</h3>

<h3>While tracking is widespread over the
internet, there are things you can do to minimize these activities with respect
to browser cookies; however, please note that even if you opt out using the
mechanisms described below, advertisements may still be visible to you when
using the Site.</h3>

<h3><i>Do Not
Track Settings</i></h3>

<h3>You may turn on the Do Not Track (“DNT”)
setting in your browser, which will enable your browser to send a special
signal to websites, analytics companies, plug in providers, and other web
services you encounter while browsing to stop tracking your activity. To turn
on your DNT signal, please visit <a target="_blank"
href="https://allaboutdnt.com/#adjust-settings">https://allaboutdnt.com/#adjust-settings</a>. Please note that not all companies will respond to or
honor DNT signals.</h3>

<h3>Note that Company does not process or
respond to web browsers’ “do not track” signals or other similar transmissions
that indicate a request to disable online tracking of users who use the Site.</h3>

<h3><i>Google
Analytics Opt-Out</i>: You can download the Google Analytics
opt-out browser add-on at: <a target="_blank"
href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a></h3>

<h3><i>Targeting/Advertising
Cookies:</i> You can opt out of targeted advertising
by:</h3>

<p>·
Facebook
- <a target="_blank" href="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</a></p>

<p>·
Google
- <a target="_blank" href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a></p>

<h3>In addition, some of our third party analytics and/or
advertising/targeting partners may be members of the Network Advertising
Initiative (&quot;NAI&quot;) or Digital Advertising Alliance (&quot;DAA&quot;)
Self-Regulatory Program for Online Behavioral Advertising. NAI and DAA each provide
information regarding procedures for opting-out of targeted online advertising
from participating companies.</h3>

<h3>· For information from NAI about opting out, click <a target="_blank" href="http://optout.networkadvertising.org/?c=1">here</a></h3>

<h3>· For information from DAA about opting out, click <a target="_blank"
href="http://www.aboutads.info/consumers">here</a> or
visit: <a target="_blank" href="http://optout.aboutads.info/">http://optout.aboutads.info/</a></h3>

<h3><i>Mobile
Identifiers</i></h3>

<h3>On your mobile device, your operating system may provide
you with additional options to opt out of interest based advertising or to
otherwise reset your mobile identifiers. For example, you may use the “Limit Ad
Tracking” setting (on iOS devices) or a setting to “Opt out of Interest-Based
Ads” (on Android) which allows you to limit the use of information about your
use of apps for purposes of serving ads targeted to your interests.</h3>

<h2>c. Aggregated Data</h2>

<p>With the personal
information and other data (including, Usage Information) collected by us, we
process “<i>Aggregated Data</i>”, such as statistical or demographic data. Aggregated
Data may be derived from personal information, but is not considered personal
information under the law if it does not directly or indirectly reveal your
identity. If we combine or connect Aggregated Data with your personal
information so that it can directly or indirectly identify you, we treat the
combined data as personal information, which will be processed in accordance
with this Privacy Notice.</p>

<h1><b>4. </b><b>Children’s Privacy</b></h1>

<p>Company does not
target the Site and/or Services to, nor knowingly collect personal information,
persons under <a name="_Hlk74748191">the age of 13</a>. Therefore, we ask you
not to provide us with personal information of persons under the age of 13. If
we learn that personal information of persons under the age of 13 has been
collected on or through the Site and/or Services, then we may deactivate the
account or otherwise terminate access to the Site and Services and/or make the
user content inaccessible.</p>

<h1><a name="_Sensitive_Data"></a><b>5. </b><b>Sensitive
Data</b></h1>

<p>Company does not
require you to provide any sensitive data about your race or ethnicity,
religious or philosophical beliefs, sex life, sexual orientation, political
opinions, trade union membership, health and genetics, or biometric data to use
the Site and/or Services.</p>

<h1><a name="_Payment_Processing_1"></a><a
name="_Using_Personal_Information"></a><b>6. </b><b>How Air Protein Uses Personal Information</b></h1>

<h1>Depending on how you use the Site, we use your personal
information in the following instances:</h1>

<h1>· To provide the Site and/or Services to you and other visitors;</h1>

<h1>· To deliver direct marketing communications regarding our products
and services that we may think are of interest to you;</h1>

<h1>· To respond to your queries and requests, or otherwise communicate
directly with you;</h1>

<h1>· To perform system maintenance and upgrades, and enable new
features;</h1>

<h1>· To enforce and/or protect our rights and the rights of others;</h1>

<h1>· To comply with a legal or regulatory obligation (for example,
keeping records of our sales for tax compliance); and</h1>

<h1>· To provide information to regulatory bodies when legally required,
and only as outlined below under <u>Legal
Obligations and Security</u>.</h1>

<p>&nbsp;</p>

<h1><b>7. </b><b>Sharing Personal Information</b></h1>

<p>Aside from
disclosing your information to those of our personnel who are authorized to
process the information in order to provide the Site and/or Services and who
are committed to confidentiality, we disclose your personal information only to
the third parties as described below.</p>

<h2><a
name="_ThirdParty_Service_Providers"></a>a. Third-Party Service Providers</h2>

<p>We share personal
information with third parties that provide services to us that help us in the
operation, provision, administration and management of the Site and/or Services
(“<b>Service Providers</b>”), and to otherwise operate our business. Depending
on how you use the Site and/or Services, the following categories of third
parties collect data on our behalf or receive personal information:</p>

<p>· Hosting providers,</p>

<p>· Analytics providers,</p>

<p>· Providers of business operations and communication tools,</p>

<p>· Other third-party service providers that help us provide features and functions for the Site
and/or Services (e.g., customer support providers), and</p>

<p>· Professional service providers, such as auditors, lawyers, consultants, accountants and
insurers.</p>

<p>For a list of all Service Providers we use, please <a href="#_Contact_Us">contact us</a>. We require all Service
Providers to respect the security of your personal information and to treat it
in accordance with the law.</p>

<h2><a name="_Business_Transfers"></a><a name="_Sharing_Between_Linked"></a>b. Business
Transfers</h2>

<p>We may also share data with third parties to whom we choose to sell, transfer, or merge parts of
our business or our assets. Alternatively, we may seek to acquire other
businesses or merge with them. If a change happens to our business, then the
new owners may use your personal information in the same way as set out in this
Privacy Notice.</p>

<h2><a name="_Affiliates_and_Subsidiaries"></a>c. Affiliates and Subsidiaries</h2>

<p>Personal information that we collect about you through or in connection with your use of
the Site and/or Services may be shared with the employees, contractors, and
agents of Company and our affiliated and subsidiary entities (“<b>Affiliates</b>”)
who are involved in providing or improving the Site and/or Services that we
offer to you. We obligate the employees, contractors and agents of Company and
our Affiliates to ensure the security and confidentiality of your personal
information and to act on that personal information only in a manner consistent
with this Privacy Notice.</p>

<h2><a
name="_Ref111908967"></a><a name="_Legal_Obligations_and"></a>d. Legal
Obligations and Security</h2>

<p>If we are required
to disclose personal information by law, such as pursuant to a subpoena,
warrant or other judicial or administrative order, our policy is to respond to
requests that are properly issued by law enforcement within the United States.
Under such circumstances, unless prohibited by applicable law, we will attempt
to provide you with prior notice that a request for your information has been
made in order to give you an opportunity to object to the disclosure. We will
attempt to provide this notice by email, if you have given us an email address.
However, government requests may include a court-granted non-disclosure order,
which prohibits us from giving notice to the affected individual. In cases
where we receive a non-disclosure order, we will notify you when it has expired
or once we are authorized to do so.</p>

<p>Note that if we
receive information that provides us with a good faith belief that there is an
exigent emergency involving the danger of death or serious physical injury to a
person, we may provide information to law enforcement trying to prevent or
mitigate the danger (if we have it), to be determined on a case-by-case basis.</p>

<h2>e. With
your Consent</h2>

<p>There may be
situations where you are asked to consent to share personal information with
third parties for additional reasons not included in this Privacy Notice. In
such event, we will only share such personal information if we have received
your prior consent and only for the purposes as listed in the request to share
such information.</p>

<h1><a name="_Cookies_and_Tracking"></a><b>8. </b><b>Marketing
Communications</b></h1>

<p>If you have signed
up to receive direct marketing emails from us, we may use your personal
information to send you marketing information about the Services, other Company
products and services, new product releases, and new feature releases of our
products and/or services that we think may interest you. We carry out direct
marketing by email.</p>

<p>If you no longer
wish to receive marketing communications, you have the right at any time to opt
out as further explained in <a href="#_Direct_Marketing">Your
Choices</a>.</p>

<h1><a name="_Managing_Your_Preferences"></a><a
name="_Your_Choices"></a><b>9. </b><b>Your Choices</b></h1>

<h2>a. Accessing,
Updating and Correcting Personal information</h2>

<p>If you would like
to correct or update certain personal information (such as your contact
information), please <a href="#_Contact_Us">contact us</a> and we will use
reasonable efforts to correct and/or update such information.</p>

<h2><a
name="_Direct_Marketing"></a>b. Direct Marketing</h2>

<p>You may manage the
receipt of marketing and non-transactional communications by clicking on the
“unsubscribe” link located on the bottom of any of our marketing e-mails. We
will use commercially reasonable efforts to process such requests in a timely
manner. Note that you cannot opt out of receiving transactional e-mails or
communications related to the Site (e.g., requests for support), which, for
clarification, are not marketing communications.</p>

<h1><a name="_Childrens_Privacy"></a><a
name="_Third_Party_Social"></a><b>10. </b><b>Third Party Social Plug-Ins</b></h1>

<p>On or through the Site,
we provide third-party “share” buttons which enable you to share certain
content via social media sites (e.g., Facebook, Twitter, Instagram, YouTube,
and LinkedIn). These “share” buttons may function as web beacons when you
interact with the button. Please note that when you “share” using the buttons,
you may send to the third party provider of the “share” button the information
that you are viewing. If you are not logged into your account with the third
party provider, then the third party may not know your identity. If you are
logged in to your account with the third party, then the third party may be
able to link information or actions about your interactions with the Site to
your account with the applicable third party provider. Please refer to each
third party’s privacy policies to learn more about its data practices.</p>

<h1><a name="_App_Stores_and"></a><b>11. </b><b>External
Websites</b></h1>

<p>On or through the Site,
we may provide or make available, for informational purposes only, links to
other websites or resources with which we do not have a contractual
relationship and over which we do not have control (“<b>External Websites</b>”).
Such links are not paid advertisements, nor do they constitute an endorsement
by Company of those External Websites, and are provided to you only as a
convenience. By clicking on links to External Websites, the operators of the
External Websites may collect your personal information. We are not responsible
for the content or data collection practices of those External Websites, and
your use of External Websites is subject to their respective terms of use and
privacy policies.</p>

<h1><a name="_Advertising"></a><a name="_Data_Retention"></a><b>12. </b><b>Data
Retention</b></h1>

<p>Personal
information is processed for the period necessary to fulfill the purposes for
which it is collected, to comply with legal and regulatory obligations and for
the duration of any period necessary to establish, exercise or defend any legal
rights. </p>

<p>In order to
determine the most appropriate retention periods for your personal information,
we consider the amount, nature and sensitivity of your information, the reasons
for which we collect and process your personal information, and applicable
legal requirements. </p>

<p>In some instances,
we may choose to anonymize personal information instead of deleting it, for
statistical use, for instance. When we choose to anonymize, we make sure that
there is no way that the personal information can be linked back to any specific
individual.</p>

<h1><a name="_Data_Security_and"></a><b>13. </b><b>Data
Security and Protection</b></h1>

<p>We have put in
place reasonable and appropriate security measures designed to prevent your
personal information from being accidentally lost, used or accessed in an
unauthorized way, altered or disclosed. For example, we use encryption, secure
socket layer (SSL), firewalls, and password protection. In addition, we limit
access to personal information to those employees, agents, contractors and the
third parties who have a business need-to-know.</p>

<h1><a name="_International_Transfers"></a><a
name="_California_Privacy_Rights"></a><b>14. </b><b>California
Privacy Rights - Shine the Light</b></h1>

<p>Company does not share your personal information with any unaffiliated third party for
its direct marketing purposes without your express consent.</p>

<p>California residents who have provided us with personal information have the right (under
California Civil Code §1798.83) to request and obtain from us, once each year,
the details of any personal information we shared with a third party for that
third party’s direct marketing purposes during the prior calendar year. The
details would include the categories of personal information and the names and
addresses of the third party with which it was shared.</p>

<p>To request information about this sharing, you may submit a request with &quot;Your
California Privacy Rights&quot; in the subject line, along with your first and
last name, and complete mailing address (including street address, city, state,
and zip code) by either: sending an email message or writing to us at the email
and/or mailing address specified in the <a
href="#_How_to_Contact">How to Contact Us</a> Section.</p>

<p>If you do not submit and send your request with complete
information as set forth above, we will not process it.</p>

<h1><a name="_Nevada_Privacy_Rights"></a><b>15. </b><b>Nevada
Privacy Rights</b></h1>

<p>Under Nevada law, certain
Nevada residents may opt out of the sale of covered personal information (which
includes first and last name, address, email address, phone number, Social
Security Number, or an identifier that allows a specific person to be contacted
either physically or online) to a person for that person to license or sell
such information to additional persons.</p>

<p>Company does not
currently sell your covered information as defined under applicable Nevada law;
however, you may submit an opt-out request, which we will honor as required by
Nevada law should we engage in such a sale in the future.</p>

<p>You may submit an
opt-out request by sending an email message or writing to us at the email
and/or mailing address specified in the <a
href="#_Contact_Us">How to Contact Us</a> Section; along with your full name, any user name, and
complete mailing address (including street address, city, state, and zip code),
email address (so that we can contact you, if needed, in connection with the
request) and confirmation that you are a Nevada resident.</p>

<p>You must provide us
with information reasonably requested by us to verify your identity and the
authenticity of the request. We typically process these requests within 60 days
from the day we receive it (and will notify you within that period if we need
an additional 30 days to do so).</p>

<h1><b>16. </b><b>International
Transfers</b></h1>

<p>Air Protein is
based in the United States. The personal information that we collect is sent to
and stored on servers located in the United States. Such storage is necessary
in order to process the information. Air Protein operates globally and may
transfer the personal data that we collect from you to our other offices and/or
to the third parties mentioned in the circumstances described above, which may
be situated outside of your country or regional area, and may be processed by
staff operating outside of your country or regional area. In particular,
information provided to us or collected by us likely will be transferred to and
processed in the United States by us or our Affiliates and our respective
agents and contractors. The data protection laws of the United States or other
countries may not be as comprehensive or equivalent to those in your country of
residence.</p>

<h1><a name="_Contact_Us"></a><a name="_How_to_Contact"></a><b>17. </b><b>How
to Contact Us</b></h1>

<p>If you have any questions about this Privacy Notice, including any requests to
exercise your legal rights, <a name="_Hlk58596402">please contact us as
follows:</a></p>

<p><a
name="_Hlk67064965">By
email at: </a><a
href="mailto:privacy@airprotein.com?subject=Privacy%20Request">privacy@airprotein.com</a>; <i>Subject Line</i>: Privacy Request</p>

<p>By mail at:  Air Protein, Inc.</p>

<p> Attn:
Air Protein Privacy Request</p>

<p>2020 Williams Street B1, San Leandro, CA 94577</p>

</Grid>
    </div>
}

export default Privacy