import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import "./style.scss";
import NewsTile from "../../../components/NewsTile";

const Section7 = () => {
  const ref = useRef(null);
  const [news, setNews] = useState(null);
  const [errorNews, setErrorNews] = useState(null);
  !errorNews &&
    !news &&
    fetch("/api/news").then((resp) =>
      resp
        .json()
        .then((x) => setNews(x.filter((f, i) => i < 6)))
        .catch((err) => setErrorNews(err))
    );

  // const calcTrackWidth = () => {
  //   const tileWidth = window.innerWidth > 1400 ? window.innerWidth * 0.2 : 330;
  //   return (4 + 1) * tileWidth + 4 * 50;
  // };

  // useEffect(() => {
  //   const element = ref.current;
  //   const animations = [];

  //   animations.push(
  //     gsap.to(element.querySelector("#wavy-text-path-3"), {
  //       scrollTrigger: {
  //         trigger: element.querySelector(".js-section-7"),
  //         start: "top bottom",
  //         end: "200%",
  //         toggleActions: "play none none none",
  //       },
  //       attr: { startOffset: "2%" },
  //       ease: "power1",
  //       duration: 2,
  //     })
  //   );

  //   ScrollTrigger.matchMedia({
  //     "(min-width: 801px)": () => {
  //       const fullWidth = window.innerWidth * 0.5 + calcTrackWidth();
  //       const fullMove = fullWidth - window.innerWidth;

  //       animations.push(
  //         gsap.to(element.querySelector(".js-side-scrolling-text"), {
  //           scrollTrigger: {
  //             trigger: element.querySelector(".js-section-7"),
  //             start: "top top",
  //             end: "200%",
  //             scrub: 0.5,
  //             pin: true,
  //           },
  //           transform: `translateX(-${fullMove}px)`,
  //         })
  //       );
  //     },
  //   });

  //   return function cleanup() {
  //     animations.forEach((a) => {
  //       try {
  //         a.kill();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  //   };
  // }, []);

  return (
    <>
      <div className="featured-press">
        <div className="content">
          <div className="title">Featured Press</div>
          <div className="card-wrapper">
            {news &&
              !errorNews &&
              news
                .slice(0, 6)
                .map((article) => (
                  <NewsTile
                    key={article._id}
                    className="mb30@md mr0@md"
                    news={article}
                  />
                ))}
          </div>
          <Link to="/press-and-media" style={{ textDecoration: "none" }}>
            <div className="btn-see-all">See All Stories</div>
          </Link>
        </div>
      </div>
      {/* <div className="section-7 posr z1" ref={ref}>
        <div className="js-section-7 posr z1 bgc-white w100vw h100vh oh pt40vh@md h-auto@md">
          <div className="js-section-7-start posa t0"></div>
          <div className="js-section-7-end posa b0"></div>
          <div className="js-wavy-text-3 posa w75% t0 l3vw w100%@lgx t-15vh@lgx w150%@md t0vh@md w175%@sm">
            <svg viewBox="0 -100 1000 500">
              <defs>
                <style
                  dangerouslySetInnerHTML={{
                    __html: `#wavy-text-path-3 { min-height: 0vh; font-size: clamp(32px, 3vw, 56px); }`,
                  }}
                ></style>
                <path
                  id="wavy-path-3"
                  d="M32.1269531,192.219727 C148.305542,39.4355469 538.659424,39.4355469 664.5,192.219727 C790.340576,345.003906 1151.19653,345.003906 1286.59375,192.219727"
                />
              </defs>
              <text id="wavy-text" fill="#FF8733" textLength="45%">
                <textPath
                  fill="#FF8733"
                  id="wavy-text-path-3"
                  xlinkHref="#wavy-path-3"
                  textLength="45%"
                  startOffset="100%"
                >
                  &nbsp;&nbsp;FEATURED PRESS
                </textPath>
              </text>
            </svg>
          </div>
          <div
            className="js-side-scrolling-text df aic b0 posa l50vw h75vh fxdrc@md h-auto@md l0@md w100%@md mb20vh@md posr@md"
            style={{
              ...(window.innerWidth > 800
                ? { width: `${calcTrackWidth()}px` }
                : {}),
            }}
          >
            {news &&
              !errorNews &&
              news
                .slice(0, 4)
                .map((article) => (
                  <NewsTile
                    key={article._id}
                    className="mb30@md mr0@md"
                    news={article}
                  />
                ))}
            <div className=" w20vw h22vw bdrs25 bgc-crusta c-white pl40 pr40 pt35 pb35 df fxdrc jcsb mr50 w306@lgx h321@lgx mr0@md mb10vh@md">
              <h3 className="fz3vw lh1.14 fz48@lgx">See More Stories</h3>
              <Link
                className="dib tdn c-white fz1vw bd3-s-white bdrs25 pl30 pr30 pt10 pb10 tac fz14@lgx"
                to="/press-and-media"
              >
                NEWSROOM
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Section7;
