import React from 'react';
const Logo = ({className}) => 
<svg className={className} version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 1008.23 311.34" style={{enableBackground:`new 0 0 1008.23 311.34`}}>
<g>
	<path class="st1" d="M562.27,32.32c-9.96,0-18.39,5.11-22.26,10.64V33.7H524.8v98.01h15.21V93.55c3.87,5.53,12.3,10.64,22.26,10.64
		c18.94,0,33.31-16.17,33.31-35.94S581.2,32.32,562.27,32.32 M559.5,90.37c-12.3,0-20.73-9.68-20.73-22.12
		c0-12.44,8.43-22.12,20.73-22.12c12.17,0,20.6,9.68,20.6,22.12C580.1,80.7,571.66,90.37,559.5,90.37"/>
	<path class="st1" d="M625.57,46V33.7h-15.21v69.12h15.21V69.09c0-13.82,7.88-21.15,16.59-21.15c2.76,0,4.97,0.28,7.6,1.24V33.56
		c-1.8-0.42-3.87-0.55-5.95-0.55C635.94,33.01,628.2,38.12,625.57,46"/>
	<path class="st1" d="M691.65,32.32c-20.46,0-36.22,15.9-36.22,35.94s15.76,35.94,36.22,35.94c20.46,0,36.22-15.9,36.22-35.94
		S712.11,32.32,691.65,32.32 M691.65,90.1c-12.31,0-20.87-9.54-20.87-21.84s8.57-21.84,20.87-21.84c12.16,0,20.73,9.54,20.73,21.84
		S703.81,90.1,691.65,90.1"/>
	<path class="st1" d="M764.92,14.48h-15.21V33.7h-14.38v13.55h14.38v33.31c0,15.62,10.37,22.95,23.64,22.95
		c4.84,0,8.16-0.55,11.33-1.52V88.44c-2.21,0.97-5.25,1.38-8.16,1.38c-7.88,0-11.61-3.04-11.61-11.75V47.25h19.77V33.7h-19.77V14.48
		z"/>
	<path class="st1" d="M833.01,32.32c-21.43,0-35.53,15.48-35.53,35.94c0,20.73,14.79,35.94,35.39,35.94
		c17.7,0,29.86-10.23,33.87-23.09h-15.9c-3.18,6.64-9.68,9.68-17.42,9.68c-12.16,0-20.46-8.3-20.87-18.8h55.02
		C869.09,48.49,854.16,32.32,833.01,32.32 M812.97,61.21c2.07-10.64,10.09-16.17,19.63-16.17c8.43,0,16.31,4.98,18.66,16.17H812.97z
		"/>
	<path class="st1" d="M891,0.11c-5.53,0-10.23,4.56-10.23,10.09c0,5.67,4.7,10.23,10.23,10.23c5.67,0,10.09-4.56,10.09-10.23
		C901.09,4.67,896.67,0.11,891,0.11"/>
	<rect x="883.4" y="33.7" class="st1" width="15.21" height="69.12"/>
	<path class="st1" d="M954.05,32.32c-8.99,0-16.73,3.73-21.01,10.78v-9.4h-15.2v69.12h15.2V68.81c0-14.93,7.74-22.67,17.83-22.67
		c8.85,0,14.38,6.5,14.38,17.56v39.12h15.2V61.21C980.45,43.65,970.08,32.32,954.05,32.32"/>
	<rect x="250.85" y="131.71" class="st0" width="43.93" height="179.51"/>
	<path class="st0" d="M105.6,98.95C47.28,98.95,0,146.23,0,204.55s47.28,105.6,105.6,105.6c15.2,0,23.32-1.98,30.71-3.62v-42.59
		c0,0-12.55,2.87-30.71,2.87c-34.39,0-62.27-27.88-62.27-62.27c0-34.39,27.88-62.27,62.27-62.27c34.39,0,62.27,27.88,62.27,62.27
		c0,0.25-0.01,0.5-0.02,0.75h0.02v106.36h43.34V205.99h-0.02c0.01-0.48,0.02-0.96,0.02-1.44C211.21,146.23,163.92,98.95,105.6,98.95
		"/>
	<path class="st0" d="M272.81,34.68c-14.12,0-25.56,11.44-25.56,25.56S258.7,85.8,272.81,85.8c14.12,0,25.56-11.44,25.56-25.56
		S286.93,34.68,272.81,34.68"/>
	<path class="st0" d="M332.18,201.54c0,23.8,0.45,109.67,0.45,109.67h43.03l0.39-95.4c0-53.98,27.96-68.45,52.14-68.45
		c12.05,0,22.11,2.43,31.58,8.66l18.94-38.49c-13.77-7.7-33.62-13.47-50.52-13.47C375.46,104.06,332.18,135.44,332.18,201.54"/>
</g>
<g>
	<path class="st1" d="M998.81,103.18c-5.08,0-9.26-4.17-9.26-9.28c0-5.11,4.17-9.29,9.26-9.29c5.11,0,9.31,4.17,9.31,9.29
		C1008.12,99.01,1003.92,103.18,998.81,103.18z M998.81,101.38c4.12,0,7.51-3.36,7.51-7.49c0-4.12-3.39-7.49-7.51-7.49
		c-4.07,0-7.46,3.36-7.46,7.49C991.34,98.02,994.73,101.38,998.81,101.38z M995.82,89.16h3.52c1.77,0,2.88,1.19,2.88,2.76
		c0,1.27-0.71,2.1-2,2.48l3.42,4.2h-2.15l-3.31-4.02h-0.56v4.02h-1.8V89.16z M999.14,93.03c0.78,0,1.32-0.43,1.32-1.11
		c0-0.71-0.53-1.09-1.32-1.09h-1.52v2.2H999.14z"/>
</g>
</svg>



export default Logo;