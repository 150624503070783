import React, { useState, useEffect, useRef } from "react";

import Grid from "../../blocks/Grid";

export default function FounderVideo({ className }) {
  const [playedOnce, setPlayedOnce] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showPlaySign, setShowPlaySign] = useState(true);
  const ref = useRef(null);

  const scrollFunction = () => {
    if (showPlaySign || !videoPlaying) return;

    const vid = ref.current;
    const { top, height } = vid.getBoundingClientRect();
    const nextSection = document.querySelector(".js-section-3-start");

    if (
      top + height < 0 ||
      top > window.innerHeight ||
      nextSection.getBoundingClientRect().top > window.innerHeight
    ) {
      vid.pause();
      setVideoPlaying(false);
    }
  };

  const videoStart = () => setVideoPlaying(true);
  const videoStop = () => setVideoPlaying(false);

  const toggleVideo = () => {
    const vid = ref.current;
    vid.controls = true;
    vid.autoplay = false;
    vid.loop = false;
    vid.muted = false;

    if (!playedOnce) {
      vid.currentTime = 0;
      setShowPlaySign(false);
      setVideoPlaying(true);
      setPlayedOnce(true);
      window.setTimeout(() => vid.play(), 0);
    } else if (showPlaySign) {
      setShowPlaySign(false);
      setVideoPlaying(true);
      window.setTimeout(() => vid.play(), 0);
    } else {
      setShowPlaySign(true);
      setVideoPlaying(false);
      window.setTimeout(() => vid.pause(), 0);
    }
  };

  const handleSizing = () => {
    if (!ref) return;

    ref.current.classList.remove("w100vw");
    ref.current.classList.remove("w100vh");

    // if (window.innerWidth > 800)
    //   ref.current.classList.add(
    //     window.innerWidth / window.innerHeight >= 1.78 ? "w100vw" : "h100vh"
    //   );
    // else ref.current.classList.add("w100vw");
  };

  useEffect(() => {
    handleSizing();

    window.addEventListener("resize", handleSizing);
    window.addEventListener("scroll", scrollFunction);

    const vid = ref.current; //weak ref
    vid.addEventListener("play", videoStart);
    vid.addEventListener("pause", videoStop);

    return () => {
      window.removeEventListener("resize", handleSizing);
      window.removeEventListener("scroll", scrollFunction);
      try {
        const vid = ref.current;
        if (vid) {
          vid.removeEventListener("play", videoStart);
          vid.removeEventListener("pause", videoStop);
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [ref, showPlaySign, videoPlaying]);

  return (
    <div className={`founder-video h100% ${className || ""}`}>
      <div
        className="founder-video__video-container posr h100%"
        onClick={toggleVideo}
        style={{
          maxWidth: "590px",
          transform: "translate(0%, -35%)",
          margin: "auto",
        }}
      >
        {showPlaySign && (
          <svg
            className="posa center w8vw z1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48.88 48.88"
          >
            <circle fill="#034b4e" cx="24.44" cy="24.44" r="24.44" />
            <polygon
              fill="#fff"
              points="17.92 10.88 38.95 23.44 17.92 36 17.92 10.88"
            />
          </svg>
        )}
        <video
          ref={ref}
          className={`founder-video__video curp js-founder-video posa z0 posr@md`}
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          style={{
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
				{window.innerWidth > 800 &&
				<source src="https://d172nc0scfm542.cloudfront.net/video/manifesto-1080.mp4"/>
				}
				{window.innerWidth <= 800 && window.innerWidth > 600 &&
				<source src="https://d172nc0scfm542.cloudfront.net/video/manifesto-720.mp4"/>
				}
				{window.innerWidth <= 600 &&
				<source src="https://d172nc0scfm542.cloudfront.net/video/manifesto-480.mp4"/>
				}
        </video>
      </div>
    </div>
  );
}
