import React from "react";

const OrangeRadio = ({label, checked, setChecked, name, index}) => {
    return 								<span onClick={_ => setChecked(checked ? -1 : index)}><span
    className={`dib round-element ${ checked ? 'bgc-crusta' : 'bd1-s-crusta'  }`}
    style={{
      height : '1.5vw', width : '1.5vw',
      ...(checked ? { border : '2px solid white', boxShadow : '0 0 1px 1px #FF8033', } : {  })
    }}
  ></span></span>
}

export default OrangeRadio