import React, { useRef, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Grid from '../../../blocks/Grid';
import once from '../../../util/once';

let s4KillAutoFlag = false;
let s4LastTop = 0;

const Section4 = () => {
  const ref = useRef(null);
  const infVid = useRef(null);

  const [played, setPlayed] = useState(false);

  const scrollToCarbonNegative = () => {
    try {
      if (s4KillAutoFlag) return;
      const speed = 10;
      const carbonText = document.querySelector('.js-carbon-text');

      if (!carbonText) return;

      if (carbonText.getBoundingClientRect().top - 100 > speed) {
        s4LastTop = window.scrollY + speed;
        window.scrollTo(0, s4LastTop);
        requestAnimationFrame(scrollToCarbonNegative);
      } else window.removeEventListener('scroll', killAutoScroll);
    } catch (e) {
      console.log(e);
    }
  };

  const killAutoScroll = () => {
    try {
      if (window.scrollY !== s4LastTop && window.scrollY < s4LastTop) {
        s4KillAutoFlag = true;
        window.removeEventListener('scroll', killAutoScroll);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const autoScrollFn = once(() => {
    try {
      window.addEventListener('scroll', killAutoScroll);
      s4LastTop = window.scrollY;
      window.addEventListener('scroll', killAutoScroll);
      scrollToCarbonNegative();
    } catch (e) {
      console.log(e);
    }
  });

  const playCarbonVid = () => {
    const video = document.querySelector('.js-carbon-negative-video');
    if (!video) return;
    video.currentTime = 0;
    video.play();
  };
  const pauseCarbonVid = () => {
    const video = document.querySelector('.js-carbon-negative-video');
    if (!video) return;
    video.pause();
  };

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    ScrollTrigger.matchMedia({
      '(min-width: 801px)': () => {
        const video = infVid.current;

        animations.push(
          gsap.to(video, {
            scrollTrigger: {
              trigger: element.querySelector('.js-infinity-video-snap'),
              start: 'top top',
              toggleActions: 'play none none none',
              once: true,
              onRefresh: () => {
                video.classList.remove('w100vw');
                video.classList.remove('h100vh');
                video.classList.add(
                  window.innerWidth / window.innerHeight >= 1.78
                    ? 'w100vw'
                    : 'h100vh'
                );
              },
              onToggle: once(({ isActive, direction }) => {
                try {
                  if (!played && video) {
                    video.play();
                    setPlayed(true);
                    setTimeout(() => autoScrollFn(), 2500);
                  }

                  if (!isActive && direction === 1) playCarbonVid();
                } catch (e) {
                  console.log(e);
                }
              }),
            },
          })
        );

        animations.push(
          gsap.to(element.querySelector('.js-carbon-negative-section'), {
            scrollTrigger: {
              trigger: element.querySelector('.js-carbon-negative-section'),
              start: `${window.innerHeight * 0.3} top`,
              start: 'top top',
              end: `${window.innerHeight * 1.8} bottom`,
              onToggle: ({ isActive, direction }) => {
                if (isActive && direction === -1) playCarbonVid();
                else if (!isActive) pauseCarbonVid();
              },
            },
          })
        );
      },
    });

    return function cleanup() {
      window.removeEventListener('scroll', killAutoScroll);
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  return (
    <div className='section-4 posr z1' ref={ref}>
      <div className='js-section-4 posr z1 h300vh w100vw oh bgc-plantation-video h-auto@md'>
        <div className='js-section-4-start posa t0'></div>
        <div className='js-section-4-end posa b0'></div>
        {window.innerWidth > 800 && (
          <div className='js-infinity-video-snap h100vh'>
            <video
              ref={infVid}
              className='js-infinity-video posr center-hori'
              src='https://d172nc0scfm542.cloudfront.net/video/infinity-720-60.mp4'
              playsInline={true}
              preload='auto'
              muted={true}
            />
          </div>
        )}
        <div className='js-carbon-negative-section h150vh h-auto@md bgc-plantation-video pt10vh@md'>
          <div className='posa h100vh w50% t50% r0 w100%@md h-auto@md posr@md df@md jcfe@md'>
            {window.innerWidth > 800 && (
              <video
                className='js-carbon-negative-video maw100vw h140vh posa r0 center-vert z0'
                muted={true}
                playsInline={true}
                loop={true}
                preload='auto'
              >
                <source
                  src='https://d172nc0scfm542.cloudfront.net/video/carbon-negative-hevc.mov'
                  type='video/quicktime'
                />
                <source
                  src='https://d172nc0scfm542.cloudfront.net/video/carbon-negative.webm'
                  type='video/webm'
                />
              </video>
            )}
            {window.innerWidth <= 800 && (
              <img
                className='js-carbon-negative-image w40vw w50vw@sm'
                src='https://d172nc0scfm542.cloudfront.net/home/carbon-negative.png'
              />
            )}
          </div>
          <Grid className='h100vh c-white posr z1 h-auto@md mt-25vh@md mt0@sm'>
            <div className='posa t50% w40% w50%@md posr@md w80%@sm w100%@smx'>
              <h2 className='js-carbon-text mb1vh mih0vh fz-clamp(32,4.5vw,62) lh1.2'>
                Carbon&nbsp;Negative.
                <br />
                Earth Positive.
              </h2>
              <div className='mih0vh fz1vw fz16@lgx'>
                <p className='mb2vh'>
                  Agriculture and forestry make up about 24% of global
                  greenhouse gas emissions&mdash;that’s 8 billion metric tons of
                  CO<sub>2</sub> equivalent in our atmosphere.
                </p>
                <p className='mb4vh'>
                  And these emissions are projected to grow 80% by 2050 with
                  increased demand for meat and dairy.
                </p>
              </div>
              <h2 className='mb1vh mih0vh fz-clamp(24,2.7vw,36) lh1.2'>
                Our carbon-negative process isn’t just novel—it’s necessary.
              </h2>
              <div className='mih0vh fz1vw fz16@lgx'>
                <p className='mb2vh'>
                  By using elements of the air to produce air meat, we’re
                  removing greenhouse gases from our atmosphere and helping our
                  planet renew.
                </p>
              </div>
            </div>
          </Grid>
        </div>
        <div className='h25vh bgc-plantation-video h10vh@md'></div>
      </div>
    </div>
  );
};

export default Section4;
