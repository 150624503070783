import React from "react";

export default function NewsTile({
  className,
  news: { name, blurb, logo, thumbnail, articleLink, articleDate, logoTitle },
}) {
  return (
    <a
      className={`card news-tile curp tdn h22vw df fxdrc jcsb p25 mr50 bdrs25 bd3-s-transparent bd3-s-crusta:h bd3-s-crusta@md bd3-s-transparent h321@lgx h-auto@md ${
        className || ""
      }`}
      href={articleLink}
      target="_blank"
    >
      <div className="df jcsb aic">
        {thumbnail && thumbnail.url ? (
          <img className="news-tile__thumbnail h40 h28@lgx" src={thumbnail.url} />
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      <p className="name">{name}</p>
      {blurb ? (
          <span className="blurb fz1.125rem w100%">{blurb}</span>
        ) : (
          <span>&nbsp;</span>
        )}
      <div className="df jcsb aic botSlot">
        {logo && logo.url ? (
          <img className="news-tile__logo h40 h28@lgx" src={logo.url} />
        ) : (
          <span>&nbsp;</span>
        )}
        {(!logo || !logo.url) && logoTitle ? (
          <span className="fz1.125rem w100%">{logoTitle}</span>
        ) : (
          <span>&nbsp;</span>
        )}
        <span className="news-tile__date c-crusta fz1vw fz14@lgx read-more">
          {/* {articleDate} */}
          Read more {">"}
        </span>
      </div>
    </a>
  );
}
