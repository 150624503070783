import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Grid from "../../../blocks/Grid";
import FounderVideo from "./FounderVideo";
import "./style.scss";
import { Link } from "react-router-dom";

const Section2 = () => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    ScrollTrigger.matchMedia({
      "(min-width: 801px)": () => {
        animations.push(
          gsap.to(element.querySelector(".js-floating-girl"), {
            scrollTrigger: {
              trigger: element.querySelector(".js-section-2"),
              start: "top top+=50%",
              scrub: true,
              pinSpacing: false,
              invalidateOnRefresh: true,
            },
            top: "-50vh",
          })
        );
      },
    });

    animations.push(
      gsap.to(element.querySelector(".js-orange-text-animated"), {
        scrollTrigger: {
          trigger: element.querySelector(".js-orange-text-section"),
          start: `top center`,
          toggleActions: "play none none none",
          once: true,
          duration: 2.5,
        },
        opacity: "1",
        top: "0",
      })
    );

    return function cleanup() {
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  return (
    <div id="section-2" className="section-2 posr z1" ref={ref}>
      <div className="js-section-2 posr w100vw bgc-crusta ">
        <div className="js-section-2-start posa t0"></div>
        <div className="js-section-2-end posa b0"></div>
        <div
          className="posa w100% t0 l0 h10vh z5"
          style={{
            background: "white",
          }}
        ></div>
        <div
          className="h150vh@md oh@md h175vh@smx"
          style={{
            background: "white",
            ...(window.innerWidth < 800 ? { height: "auto" } : {}),
          }}
        >
          {window.innerWidth > 800 && (
            <img
              className="js-floating-girl posa t0vh l10% l5%@md w-clamp(300,54vw,1000) posr@md center-hori@md"
              src="https://d172nc0scfm542.cloudfront.net/home/new-air-woman.png"
              style={{ zIndex: 100, top: "-200px", height: "80vh" }}
            />
          )}
          <div className="">
            <Grid className="posr h-auto h-auto@md pb30">
              <div
                className="w40% l60% posr ff-ecl mb10vh w35%@lgx l55%@lgx mb0@md w60%@md l40%@md center-hori@md w100%@sm"
                style={{ zIndex: 100, paddingTop: "50px" }}
              >
                <div className="c-elephant fz18@lgx">
                  <p className="mb16">
                  Making food doesn't have to mean deforestation, industrial animal farming, or reliance on systems that emit massive amounts of greenhouse gases that harm our planet.
                  </p>

                  <p className="mb16">
                  There's a better way forward. One that pushes the boundaries of what we thought possible and builds a sustainable relationship between our food and our planet.
                  </p>
                  <h2
                  className="c-white lh1.2 mb4vh mih0vh c-crusta"
                  style={{ fontSize: "32px" }}
                >
                  We Believe You Can Eat For The World You Want
                </h2>
                </div>
              </div>
            </Grid>
          </div>
        </div>
        {/* <div className="spearator"></div> */}
            <dev id="vidArqp">
        <video muted id="sepVid" className="sep-earth-animation" preload="auto" playsinline="" loop autoplay="">
              <source src="https://d172nc0scfm542.cloudfront.net/video/earth.mov" type="video/quicktime"/>
                <source src="https://d172nc0scfm542.cloudfront.net/video/earth.webm" type="video/webm"/>
                </video>
                </dev>
        {/* <div className="section-3-new" ref={ref}>
          <div className="content">
            <div className="heading">
              <div className="sub-heading">WE'VE REIMAGINED FERMENTATION</div>
              <div>Growing Protein Through</div>
              <div>Landless Agriculture</div>
            </div>
            <div className="main-content">
              <div className="content-wrapper">
                <div className="left">
                  <div className="text">
                  Air Protein’s landless agriculture is similar to vertical farming but grows protein rather than produce.  
                    <br /><br />
                    Solving the world’s biggest farming challenges, Air Protein’s landless protein farming reduces time to market, requires no arable land which decouples food from traditional supply chain risks, is highly scalable, and is cost effective.                    <br />
                    <br /><br />
                    Air Protein Farm<sup>TM</sup>  vs. Vertical Farm
                    <div className="iconBox">
                    <img
              className="js-ap-icons t0vh l10% l5%@md w-clamp(300,54vw,1000) posr@md center-hori@md"
              src="https://d172nc0scfm542.cloudfront.net/home/AP_Icons_horiz-left.png"
            />
            <img
              className="js-ap-icons t0vh l10% l5%@md w-clamp(300,54vw,1000) posr@md center-hori@md"
              src="https://d172nc0scfm542.cloudfront.net/home/AP_Icons_horiz-right-new.png"
            />
            </div>
                    
                  </div>
                  <Link to="/making-air-meat">
                    <div className="btn">Making Air Protein</div>
                  </Link>
                </div>
                <div className="right">
                  <FounderVideo />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        
      </div>
    </div>
  );
};

export default Section2;
