import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {Helmet} from "react-helmet";


const Contact = () => {
	const { register, handleSubmit, formState, reset } = useForm();
	const { errors, isSubmitSuccessful, isSubmitted } = formState;
	const [submitError, setSubmitError] = useState(false)
	const [dropDownAlt, setDropDownAlt] = useState(true)
	const [buttonLabel, setButtonLabel] = useState('SUBMIT')
	const onSubmit = async data => {
		if(isSubmitted) return;
		try {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify(data);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
				cors: 'no-cors'
			};

			// MC_URL Set in env file 
			await fetch(MC_URL, requestOptions)
			// send an email with stuff to some email address
			await fetch(RV_URL, requestOptions)
			reset()
			setButtonLabel('SUCCESS')
		} catch (error) {
			setSubmitError(true)
			setButtonLabel('ERROR')
			throw error
		}
	}

	return <div className="page-contact">
		<Helmet>
			<title>Air Protein | About Air Protein</title>
			<meta name="description" content="Partner with us, invest in us, learn more about us."></meta>
			<meta name="keywords" content="air meat, Alternative meat food service, Alternative meat"></meta>
		</Helmet>		

		<div className="w100% pl100 pr100 ml-auto mr-auto redPad"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/contact-us/air-lobby.jpg)',
				backgroundSize: 'cover',
				marginTop: '120px'
			}}
			><div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2">

<div className="home-section1-half">
	  </div>
<div className="home-section1-half rightSet whiteOv">
<img className='mobile-only contactImg' src="https://d172nc0scfm542.cloudfront.net/contact-us/lobby-mobile.jpg"/>

<div className="mc_embed_shell">
<div className="mc_embed_signup">
    <form action="https://airprotein.us20.list-manage.com/subscribe/post?u=4865c9ab3b24b4adc3d08e5c3&amp;id=5876daa963&amp;f_id=0085d5edf0" method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank">
        <div>
			
		<h1 className="ff-ecl mb1.5rem fz4rem fz2rem@md">Contact Us</h1>

<div className="df fxdrc w100%">
<div className="dn db@md pb1.5rem fz1rem"><a className="tdn" href="tel:15102859097">phone: 1.510.285.9097</a></div>
<div className="db dn@md pb1.5rem fz1rem">phone: 1.510.285.9097</div>
<div className="pb1.5rem fz1rem"><a className="tdn" href="mailto:information@airprotein.com">email: information@airprotein.com</a></div>
</div>

<div className=" mb0.5rem">* Required Fields</div>



<div className="row df jcsb fxdrc@lg mb-items-md">
<div className="col-6 col-12@lg pr2rem pr0@lg">
			<input type="text" name="FNAME" className="rounded maxw fz1.125rem required text" placeholder='* First Name'/>
</div>
<div className="col-6 col-12@lg pr2rem pr0@lg">
		<input type="text" name="LNAME" className="rounded maxw fz1.125rem required text" placeholder='* Last Name'/>
</div>
</div>

<div className="row df jcsb fxdrc@lg mb-items-md">
<div className="col-6 col-12@lg pr2rem pr0@lg">
<select name="MMERGE12" className="rounded maxw fz1.125rem bg dropdown-alt">
					<option>* Purpose</option>
					<option value="Buy Our Product">Buy Our Product</option>
					<option value="Sell Our Product">Sell Our Product</option>
					<option value="Investor">Investor</option>
					<option value="Partnership">Partnership</option>
					<option value="Media Enquiry">Media Enquiry</option>
					<option value="Speaking Engagement">Speaking Engagement</option>
					<option value="Non-Profit">Non-Profit</option>
					<option value="Career Opportunities">Career Opportunities</option>
					<option value="Other">Other</option>
			</select>
			</div>
<div className="col-6 col-12@lg pr2rem pr0@lg">
<input type="text" name="PHONE" className="rounded maxw fz1.125rem REQ_CSS" placeholder='Phone Number'/>
</div>
</div>
			<div className="row df jcsb fxdrc@lg mb-items-md">
<div className="col-6 col-12@lg pr2rem pr0@lg">
<input type="text" name="MMERGE5" className="rounded maxw fz1.125rem required text" placeholder='* Your Title'/>
			</div>
<div className="col-6 col-12@lg pr2rem pr0@lg">
<input type="email" name="EMAIL" className="rounded maxw fz1.125rem required email" placeholder='* Email Address'/>
</div>
</div>
<div className="row df jcsb fxdrc@lg mb-items-md">
<div className="col-6 col-12@lg pr2rem pr0@lg">
<input type="text" name="TEXTYUI_3" className="rounded maxw fz1.125rem required text" placeholder='* Company'/>
</div></div>

		<div class=" mb0.5rem">Message</div>
		<textarea type="text" name="TEXTAREAY" className="rounded maxw w100% fz1.125rem" required="" rows={10}></textarea>


		<div>
			<input style={{ display: 'none' }} type="text" name="b_4865c9ab3b24b4adc3d08e5c3_5876daa963" tabindex="-1"/></div>
			
			<div className="row df fxdrrr jcc@md aic@md mb-items-md panzer" style={{ marginTop: '20px' }}>
			<input type="submit" name="subscribe" className="cta-outline fz1.125rem ml0.5rem" value="SUBMIT"/></div>
			</div>


</form>
</div>
</div>







	</div>


	</div>
</div>

	</div>
}

export default Contact;
