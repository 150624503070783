import React from 'react';
import ReactDom from 'react-dom';

import '../scss/site.scss';

import App from './App';

ReactDom.render(
	<App />,
	document.querySelector('#app'),
)