import React from "react"
import Grid from "../blocks/Grid"
import {Helmet} from "react-helmet";


const Terms = _ => {
    return <div className="privacy-page">
        <Helmet>
            <title>Air Protein | Privacy Statements</title>
            <meta name="description" content=""></meta>
            <meta name="keywords" content=""></meta>
        </Helmet>
        <Grid className="pt6rem mb-items-md">
        <p><b>AIR PROTEIN WEBSITE TERMS OF USE</b></p>

<p>Effective: Oct 8 2022</p>

<p>These terms of use (the “<b>Terms</b>”) are a legal contract between you (“<b>you</b>” and “<b>your</b>”)
and Air Protein, Inc. (“<b>Air Protein</b>,” “<b>we</b>,” “<b>us</b>,” or “<b>our</b>”)
and govern your access to, and use of, Air Protein’s public facing corporate
website located at <a href="http://www.airprotein.com">www.airprotein.com</a> (the
“<b>Site</b>”) and Content (as defined below in Section 3).</p>

<p>For the avoidance of doubt, these Terms do not apply to any services and/or products provided by Air
Protein unless the use of such services and/or products are expressly subject
to these Terms (for example, through an authorized link at the time you obtain
such services). The use of any Air Protein services and/or products shall be
subject to the applicable consulting and/or services agreement entered into
between you and Air Protein for the applicable service(s).</p>

<p><a name="_Hlk20150127">Please be advised that AIR PROTEIN does not provide
warranties for the SITE AND/OR CONTENT and these Terms limit our liability to
you. Please see Sections 9 AND 10 for further information.</a></p>

<p>BY ACCESSING AND/OR USING THE SITE AND/OR CONTENT, YOU acknowledge that you have read, understand, and agree to be bound by these Terms. If you do not agree with any of these terms, do not access or otherwise use the Site or any
information contained on the Site.</p>

<p><b>1. </b><b>Changes
to the Site and Content; Modification of these Terms</b></p>

<p>AIR PROTEIN MAY MAKE
CHANGES TO, AND/OR DISCONTINUE OR SUSPEND, THE SITE AND/OR CONTENT AT ANY TIME
WITHOUT NOTICE. You agree that AIR
PROTEIN will not be liable to you or to any third party for any CHANGES,
suspension or discontinuance of the SITE AND/OR CONTENT (or any part thereof).</p>

<p>AIR PROTEIN RESERVES
THE RIGHT TO CHANGE OR MODIFY THESE TERMS AT ANY TIME. IF AIR PROTEIN MAKES
ANY SUCH CHANGES, AIR PROTEIN WILL POST UPDATED TERMS OF USE ON THIS SITE. If we make any material changes to these
Terms, we will notify you by posting a notice of the changes on the Site. It
is your responsibility to regularly visit and review these Terms. IF ANY
MODIFICATION IS UNACCEPTABLE TO YOU, YOUR SOLE REMEDY SHALL BE TO CEASE USING
THE SITE. IF YOU DO NOT CEASE USING THE SITE AND/OR CONTENT FOLLOWING THE DATE
WE POST THE UPDATED TERMS, YOU WILL BE DEEMED TO HAVE ACCEPTED THE CHANGE.</p>

<p><b>2. </b><b>Privacy
and personal information</b></p>

<p>For information about Air
Protein data protection practices, please read Air Protein’s Privacy Policy
available at <a
href="http://www.airprotein.com/privacy">www.airprotein.com/privacy</a>. This
policy explains how Air Protein treats your personal information, and protects
your privacy, when you provide personally identifiable information to Air
Protein in connection with your use of the Site.</p>

<p><b>3. </b><b>Access
and Use of Site and Content</b></p>

<p>Subject to your
compliance with these Terms, Air Protein grants you a limited, revocable,
nonexclusive right to access and use the Site and Content solely for your own
personal non-commercial use.</p>

<p>Air Protein and its
licensors retain all right, title and interest in and to the Site, including,
without limitation, any and all text, graphics, images, music, software, audio,
video, works of authorship of any kind, and information or other materials that
are posted, generated, provided or otherwise made available through the Site
(collectively, the “<b><u>Content</u></b>”). All Air Protein
trademarks are strictly owned by Air Protein, and nothing in these Terms will
be construed to transfer ownership rights or grant any permission, license or
other rights to any Air Protein trademark without written authorization from Air
Protein. The names of actual companies and products mentioned within the Site
and/or Content may be the trademarks of their respective owners. Air Protein
reserves all rights and licenses not expressly granted to you in these Terms
and no implied license is granted by Air Protein. The Content and Site, and its
underlying technology, are protected by copyright, trademark, patent,
intellectual property, and other laws of the United States and foreign
countries. You agree not to remove, change or obscure any copyright, trademark,
service mark or other proprietary rights notices incorporated in or accompanying
the Site and/or any Content.</p>

<p><b>4. </b><b>Prohibited
Use</b></p>

<p>As a condition of your
use of the Site and Content, you warrant to Air Protein that you will not use
the Site and/or Content for any purpose that is unlawful or prohibited by these
Terms. Specifically, you are not allowed to (directly or indirectly): (i)
resell or otherwise make commercial use of the Site and/or Content; (ii)
collect or use any images, descriptions, or other content included in the Site and/or
Content, or any portion thereof; (iii) copy, imitate, distribute, publicly
perform, or publicly display Site and/or Content; (iv) modify or otherwise make
any derivative uses of the Site and/or Content, or any portion thereof; (v) use
data mining, robots or similar data gathering or extraction methods on the Site;
(vi) perform, or release or disclose the results of, any benchmark testing or
vulnerability assessments of the Site; (vii) introduce into the Site any
viruses, trojan horses, malware, spyware, adware or other disruptive software,
or any software code, which is designed to disrupt, damage, or perform
unauthorized actions on a computer system; (viii) remove or alter any
proprietary notices or labels on or in the Site and/or Content; (ix) use the Site
and/or Content to directly or indirectly develop any product or service that
competes with the Site; (x) download (other than page caching) any portion of
the Site and/or Content or any information contained therein, except as
expressly permitted on the Site; or (xi) use the Site and/or Content other than
as expressly permitted in these Terms.</p>

<p>You may not use the Site
and/or Content in any manner that would violate any applicable laws, rules or
regulations, or in any manner which could damage, disable, overburden, or
impair the Site or interfere with any other party's use and enjoyment of the Site
and/or Content. You may not obtain or attempt to obtain any materials or
information through any means not intentionally made available or provided for
through the Site, including, but not limited to, data mining, robots, or
similar data gathering and extraction tools.</p>

<p><b>5. </b><b>Feedback</b></p>

<p>Air Protein shall be
entitled to unrestricted use of any and all comments, suggestions, ideas,
notes, drawings, concepts, problems or other information with respect to the Site
and/or Content disclosed or offered by you to us (collectively, “<b>Feedback</b>”).
We may use the Feedback for any and all purposes whatsoever, commercial or
otherwise, without any payment or other obligation to you or any other person
involved with the creation of the Feedback.</p>

<p><b>6. </b><b>Termination</b></p>

<p>Air Protein reserves
the right, in its sole discretion, to terminate and/or suspend your access to
the Site and/or Content or any portion thereof at any time, for any reason,
without notice.</p>

<p><b>7. </b><b>Links
to Third Party Sites</b></p>

<p>The Site may contain
links to other third party websites which are not controlled or owned by Air
Protein (“<b>Linked Sites</b>”). If you decide to access and use such Linked
Sites, be advised that your use is governed solely by the terms and conditions
of such Linked Sites, and Air Protein does not endorse, is not responsible for,
and makes no representations as to such Linked Sites, their content or the
manner in which they handle your data. Air Protein is not liable for any damage
or loss caused or alleged to be caused by or in connection with your access or
use of any such Linked Sites, or your reliance on the privacy practices or
other policies of such Linked Sites.</p>

<p><b>8. </b><b>Availability
of the Site</b></p>

<p>Air Protein makes no
claims regarding the availability or right to access or use of the Site and/or Content
outside of the United States. Air Protein may restrict access to the Site
and/or Content, or portions thereof, in certain countries in its sole
discretion, and the Site and/or Content may not be available or accessible in
all languages. If you use or access the Site and/or Content outside of the
United States, you are responsible for compliance with the laws and regulations
of your jurisdiction with respect to your use of the Site and/or Content in
your country of residence.</p>

<p><b>9. </b><b>Disclaimer</b></p>

<p>THE SITE AND CONTENT
(AND ALL PARTS THEREOF), AND ANY OTHER MATERIALS OR INFORMATION MADE AVAILABLE
THROUGH THE SITE, ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;. AIR
PROTEIN AND ITS LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM ANY
REPRESENTATIONS, WARRANTIES OR GUARANTEES OF ANY KIND, EXPRESS OR IMPLIED,
INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE,
SATISFACTORY PURPOSE, ACCURACY, OR NON-INFRINGEMENT.</p>

<p>AIR PROTEIN AND ITS
LICENSORS AND SUPPLIERS DO NOT WARRANT OR MAKE ANY GUARANTEE THAT SITE AND/OR
CONTENT (OR ANY PART THEREOF), OR ANY OTHER MATERIALS OR INFORMATION MADE
AVAILABLE THROUGH THE SITE: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE
COMPATIBLE WITH YOUR HOME NETWORK, COMPUTER OR MOBILE DEVICE, OR ANY THIRD
PARTY SITES; (III) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR
ERROR-FREE BASIS; OR (IV) WILL BE ACCURATE OR RELIABLE. NO ADVICE OR
INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM AIR PROTEIN OR ANY
THIRD PARTY, SHALL CREATE ANY WARRANTY.</p>

<p>AIR PROTEIN DOES NOT
WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY LINKED SITES,
ADVERTISED OR ACCESSIBLE THROUGH THE SITE AND/OR CONTENT, AND AIR PROTEIN WILL
NOT BE A PARTY TO, OR IN ANY WAY MONITOR, ANY TRANSACTION BETWEEN YOU AND THE
THIRD-PARTY OPERATORS OF SUCH LINKED SITES.</p>

<p>IF YOU ARE DISSATISFIED
WITH ANY PORTION OF THE SITE AND/OR CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS
TO DISCONTINUE USING THE SITE.</p>

<p><b>10. </b><b>Limitation
of Liability</b></p>

<p>TO THE MAXIMUM EXTENT
PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL AIR PROTEIN BE LIABLE FOR ANY
INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
WHATSOEVER, OR FOR ANY DAMAGES FOR LOSS OF USE, DATA, OR PROFITS, ARISING OUT
OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE AND/OR
CONTENT, WITH THE DELAY OR INABILITY TO USE THE SITE AND/OR CONTENT, OR FOR ANY
INFORMATION OBTAINED THROUGH THE SITE AND/OR CONTENT, OR OTHERWISE ARISING OUT
OF THE USE OF THE SITE AND/OR CONTENT, WHETHER BASED ON CONTRACT, TORT,
NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF AIR PROTEIN HAS BEEN ADVISED
OF THE POSSIBILITY OF DAMAGES. IN NO
EVENT SHALL AIR PROTEIN’S TOTAL CUMULATIVE LIABILITY ARISING FROM OR RELATED TO
THESE TERMS OR THE ACCESS, USE OF, OR INABILITY TO ACCESS OR USE THE SITE
AND/OR CONTENT (or any part thereof), and/or ANY OR ANY OTHER MATERIALS OR
INFORMATION MADE AVAILABLE THROUGH THE SITE, EXCEED ONE HUNDRED DOLLARS ($100.00).
AIR PROTEIN DISCLAIMS ALL LIABILITY OF ANY KIND OF AIR PROTEIN’S LICENSORS AND
SUPPLIERS.</p>

<p><b>11. </b><b>Application
of Disclaimers and Limitations</b></p>

<p>BECAUSE SOME
STATES/JURISDICTIONS DO NOT ALLOW THE DISCLAIMERS OF CERTAIN WARRANTIES AND/OR
THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL
OR OTHER DAMAGES, THE ABOVE DISCLAIMERS AND LIMITATIONS SET FORTH IN SECTIONS 9
AND 10 MAY NOT APPLY TO YOU. In such
event, the liability of AIR PROTEIN for such damages will be limited to the
extent permitted by applicable law in such jurisdiction.</p>

<p><b>12. </b><b>Indemnification</b></p>

<p>You agree that you are
solely responsible for (and that Air Protein has no responsibility to you or to
any third party for) any breach of your obligations under the Terms and for the
consequences (including any loss or damage which Air Protein may suffer) of any
such breach. Except as prohibited by law, you will hold Air Protein and its
officers, directors, employees and agents harmless for any indirect, punitive,
special, incidental or consequential damage, however it arises (including
attorneys' fees and all related costs and expenses of litigation and
arbitration, or at trial or on appeal, if any, whether or not litigation or
arbitration is instituted), whether in an action of contract, negligence or
other tortious action, or arising out of or in connection with these Terms,
including without limitation any claim for personal injury or property damage,
arising from (i) your use of and access to the Site and/or Content; and (ii)
your violation of any of these Terms. We reserve the right to assume control of
the defense of any third-party claim that is subject to indemnification by you,
in which event you will cooperate with us in asserting any available defenses.
This defense and indemnification obligation will survive these Terms and your
use of the Site and/or Content.</p>

<p><b>13. </b><b>Governing
Law and VENUE</b></p>

<p>These Terms shall be
governed by, construed and enforced in accordance with, the laws of the State
of California, without reference to its choice of law rules to the contrary. <a
name="_Hlk51665831">Each party hereby irrevocably consents to the exclusive
jurisdiction and venue of the federal, state, and local courts in Alameda County,
California, in connection with any action arising out of or in connection with
these Terms. Notwithstanding anything to the contrary herein, Air Protein may
seek injunctive or other appropriate relief in any court with competent
jurisdiction in any country, in the event of any actual or alleged violation of
Air Protein’s </a>intellectual and proprietary rights.</p>

<p><b>14. </b><b>General</b></p>

<p><u>Entire Agreement</u></p>

<p>The Terms constitute
the entire agreement between you and Air Protein, and supersedes all prior oral
or written agreements or communications with regard to the subject matter
described herein. <a name="_Hlk23506450">Neither the rights nor the
obligations arising under these Terms are assignable by you, and any such
attempted assignment or transfer shall be void and without effect. The
official text of these Terms (and any notice submitted hereunder) will be in
English. The parties acknowledge that they require that these Terms be drawn
up in the English language only. <i></i>In the event of any dispute concerning
the construction or meaning of these Terms, reference will be made only to
these Terms as written in English and not to any translation into another
language</a>.</p>

<p><u>Waiver</u></p>

<p>You agree that if Air
Protein does not exercise or enforce any legal right or remedy which is
contained in the Terms (or which Air Protein has the benefit of under any
applicable law), this will not be taken to be a formal waiver of Air Protein’s
rights and that those rights or remedies will still be available to Air Protein.
No waiver by either party of any breach of any provision hereof shall be deemed
a waiver of any subsequent or prior breach of the same or any other provision.</p>

<p><u>Severability</u></p>

<p>If any court of law,
having the jurisdiction to decide on this matter, rules that any provision of
these Terms is invalid, then that provision will be removed from the Terms
without affecting the rest of the Terms, which will remain in full force and
effect.</p>

<p><b>15. </b><b>Questions</b></p>

<p>If you have any
questions regarding these Terms, please send an email to <a href="mailto:legal@airprotein.com">legal@airprotein.com</a>.</p>
        </Grid>
    </div>
}

export default Terms