import { gsap } from "gsap";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Grid from "../blocks/Grid";
import ButtonCTA from "../components/ButtonCTA";
import ManiVideo from "./About/ManiVideo";

const About = () => {
  useEffect(() => {
    let tl, tl2;
    setTimeout(() => {
      const trigger = ".moonshot";
      let delayObject = {
        idx: 0,
      };
      tl2 = gsap.timeline();
      tl2
        .to(".mc-copy div", {
          opacity: "100%",
        });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: trigger,
          start: "center center",
          end: "+=2500",
          scrub: 1,
          markers: false,
          pin: trigger,
        },
      });

      tl.to(".moon-window", {
        clipPath: "circle(25% at 50% 50%)",
      })
        .to(".moon-holder", {
          left: "40%",
        })
        .to(
          ".moon-title",
          {
            opacity: "100%",
          },
          "<"
        )
        .to(".moon-copy", {
          opacity: "100%",
        })
        .to(".moon-holder", {
          opacity: "0",
        })
        .add(tl2, "circleIn")
        .to(
          ".mc-icons",
          {
            opacity: "100%",
          },
          "circleIn"
        )
        .to(delayObject, {
          idx: 1000,
        })
        .to(delayObject, {
          idx: 0,
        });
    }, 250);
    return () => {
      try {
        tl.kill();
        tl2.kill();
      } catch (error) {}
    };
  }, []);
  return (
    <div className="page-about">
      <Helmet>
        <title>Air Protein | About Air Protein</title>
        <meta
          name="description"
          content="From moonshot to protein. Inspired by NASA, designed by Air Protein. Meet the team."
        ></meta>
        <meta
          name="keywords"
          content="Carbon negative food, meat alternative, air meat, plant based meat, Lisa Dyson, ADM"
        ></meta>
      </Helmet>

      <div className="w100% ml-auto mr-auto"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/vision/ap-earth.jpg)',
				backgroundSize: 'cover',
        marginTop: '65px'
			}}
			>
      <div className="grid maw1400 w100% pl100 pr100 ml-auto mr-auto tripleWorld">
        <div className="orangeLogo">
        <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/air-logo-white.png" />
        </div>
        <div className="tripleCopy">
        Leveraging revolutionary technology to transform the way food is made is the only way we will be able to feed the planet’s 10 billion people in 2050.
        </div>
      </div>
      </div>

      <div className="w100% ml-auto mr-auto"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/vision/ap-fermentator.jpg)',
				backgroundSize: 'cover'
			}}
			>
      <div className="grid maw1400 w100% pl100 pr100 ml-auto mr-auto tripleWorld">
        <div className="whiteLogo">
        <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/air-logo-white.png" />
        </div>
        <div className="tripleCopy">
        Our AirFermentation<sup>TM</sup> technology leverages landless agriculture, enabling food security and accelerating the world’s transition to climate and rainforest-friendly food.        </div>
      </div>
      </div>
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-landless.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-pure.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-revolutionary.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-carbon-negative.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-scalable.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-supply-chain-free.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-made-anywhere.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-rainforest-friendly.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-life.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-hope.jpg" />
      <img className="responsive fullScale" src="https://d172nc0scfm542.cloudfront.net/vision/ap-future.jpg" />
      
    </div>
  );
};

export default About;
