import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home    from '../pages/Home';
import About   from '../pages/About';
import Astro   from '../pages/astro';
import AirProtein   from '../pages/AirProtein';
import Vision from '../pages/OurVision';
import Process from '../pages/Process';
import Contact from '../pages/Contact';
import Resources from '../pages/Resources';
import Careers from '../pages/Careers';
import Newsroom from '../pages/Newsroom';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms'

const Routes = () => (
<main className="site-body">
	<Switch>
	<Route path="/our-vision">
		  <Vision />
		</Route>	
		<Route path="/our-process">
		  <Process />
		</Route>	
		<Route path="/making-air-meat">
		  <Process />
		</Route>	
		<Route path="/our-story">
		  <About />
		</Route>	
		<Route path="/air-protein">
		  <AirProtein />
		</Route>	
		<Route path="/contact-us">
		  <Contact />
		</Route>
		<Route path="/faq">
		  <Resources />
		</Route>
		<Route path="/join-our-team">
		  <Careers />
		</Route>
		<Route path="/press-and-media">
		  <Newsroom />
		</Route>
		<Route path="/privacy">
		  <Privacy />
		</Route>
		<Route path="/terms">
		  <Terms />
		</Route>		
		<Route path="/astro-tech">
		  <Astro />
		</Route>		
		<Route path="/">
		  <Home />
		</Route>	
	</Switch>
</main>
)

export default Routes;
