import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';

import Grid from '../blocks/Grid';
import CloseIcon from '../components/CloseIcon';
import Logo from '../components/Logo';
import MenuIcon from '../components/MenuIcon';

const Header = () => {
	const [toggle, setToggle] = useState(false);

		  // Inject SweetAlert2 CSS
var cssLink = document.createElement('link');
cssLink.href = 'https://cdn.jsdelivr.net/npm/sweetalert2@11/dist/sweetalert2.min.css';
cssLink.rel = 'stylesheet';
document.head.appendChild(cssLink);

// Inject SweetAlert2 JS
var script = document.createElement('script');
script.src = 'https://cdn.jsdelivr.net/npm/sweetalert2@11/dist/sweetalert2.all.min.js';
document.head.appendChild(script);


	useEffect(() => {
		// Check if the cookie exists
		const myCookieValue = Cookies.get('splashOn');
	
		if (myCookieValue) {
		  console.log('Cookie value:', myCookieValue);
		} else {
		//   Swal.fire({
		// 	imageUrl: 'https://d172nc0scfm542.cloudfront.net/splash.jpg',
		// 	imageAlt: 'A tall image',
		// 	confirmButtonText: 'Visit Air Protein Site',
		// 	backdrop: false
		//   });
		//   Cookies.set('splashOn', 'set');
		}
	  }, []);



	return (
	<header
		className="header posf t0 l0 w100% df aic z100 bgc-white"
		style={{
			transition : '0.6s transform ease-out',
			transform  : 'translateY(0)',
		}}
	>
		<Grid className="w100% w80%@md w100%@sm">
			<div className="df jcsb aic w100%">
				<Link
					className="lh0 logo js-header-logo"
					to="/"
				>
					<Logo />
				</Link>
				<div className="nav dn@md">
					<NavLink className="mr24" to="/our-process">
					Our Production
					</NavLink>
					<NavLink className="mr24" to="/air-protein">
						Our Protein
					</NavLink>
					<NavLink className="mr24" to="/our-story">
						Our Story
					</NavLink>
					{/* <NavLink className="mr24" to="/our-vision">
						Our Vision
					</NavLink> */}
					<NavLink className="mr24" to="/press-and-media">
					Press
					</NavLink>
					<NavLink className="mr24" to="/contact-us">
					Contact Us
					</NavLink>
				</div>
				<div className="mobile-nav dn dib@md">
					<MenuIcon onClick={_ => setToggle(!toggle)} />
				</div>
			</div>
		</Grid>
		{toggle &&
			<div className="mobile-nav-list z104">
				<Grid className="w100% w80%@md w100%@sm">
					<div className="df jcsb aic w100%">
						<Link onClick={() => setToggle(!toggle)} className="pt10 lh0 logo js-header-logo" to="/">
							<Logo />
						</Link>
						<div className="pt1.5rem posr z1">
							<CloseIcon onClick={_ => setToggle(!toggle)} />
						</div>
					</div>
				</Grid>
				<div className="links df fxdrc jic aic mauto">

					<Link className="mr16" to="/our-process" onClick={_ => setToggle(!toggle)}>
					Our Production
					</Link>
					<Link className="mr16" to="/air-protein" onClick={_ => setToggle(!toggle)}>
						Our Protein
					</Link>
					<Link className="mr16" to="/our-story" onClick={_ => setToggle(!toggle)}>
						Our Story
					</Link>
					{/* <Link className="mr16" to="/our-vision" onClick={_ => setToggle(!toggle)}>
						Our Vision
					</Link> */}
					<Link className="mr16" to="/press-and-media" onClick={_ => setToggle(!toggle)}>
					Press
					</Link>
					<Link className="mr16" to="/contact-us" onClick={_ => setToggle(!toggle)}>
					Contact Us
					</Link>



				</div>
			</div>
		}

	</header>
	)
}

export default Header;
