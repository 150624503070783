import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Outlet
} from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {Helmet} from "react-helmet";

import Header from './global/Header';
import Footer from './global/Footer';
import PageContainer from './global/PageContainer';
import ScrollToTop from './global/ScrollToTop';
import LandscapeModeWarning from './global/LandscapeModeWarning';
import Routes from './global/Routes';

import mobileTabletCheck from './util/mobile-tablet-check';


const App = () => {
	gsap.registerPlugin(ScrollTrigger);

const isSpecificPage = window.location.pathname === '/astro-tech';
	// if(!mobileTabletCheck()) 
	// 	window.addEventListener('resize', () => location.href = location.href);
	
	return (
	<Router>
		<ScrollToTop />
		<LandscapeModeWarning />
		<Helmet>
			<meta property="og:site_name" content="AIR PROTEIN"/>
			<meta property="og:title" content="AIR PROTEIN"/>
			<meta property="og:url" content="https://www.airprotein.com"/>
			<meta property="og:type" content="website"/>
			<meta property="og:image" content="https://d172nc0scfm542.cloudfront.net/social/airprotein.jpeg"/>
			<meta property="og:image:width" content="2701"/>
			<meta property="og:image:height" content="2701"/>
			<meta name="twitter:title" content="AIR PROTEIN"/>
			<meta name="twitter:image" content="https://d172nc0scfm542.cloudfront.net/social/airprotein.jpeg"/>
			<meta name="twitter:url" content="https://www.airprotein.com"/>
			<meta name="twitter:card" content="summary"/>
		</Helmet>
		{ !isSpecificPage && <Header /> }
		<PageContainer>
			<Routes />
		</PageContainer>
		{ !isSpecificPage && <Footer /> }
	</Router>
	)
}

export default App;