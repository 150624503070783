import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Grid from '../blocks/Grid';
import ButtonCTA from '../components/ButtonCTA';
import mobileTabletCheck from '../util/mobile-tablet-check';
import {Helmet} from "react-helmet";


const Process = () => {
	const ref = useRef(null);
	const cloudVideo = useRef(null);
	const foodOneVideo = useRef(null);
	const bloomVideo = useRef(null);
	const bubbleVideo = useRef(null);
	const airFoodVideo = useRef(null);

	useEffect(() => {
		const element = ref.current;
		const animations = [];

		  gsap.fromTo(
			element.querySelector(".chicky"), 6,
			{
			  scale:.9
			},
			{
			  scale: 1
			}
		  );

		  gsap.fromTo(
			element.querySelector(".chucky"), 6,
			{
			  scale:.9
			},
			{
			  scale: 1
			}
		  );
		ScrollTrigger.matchMedia({
			'(max-width: 801px)': () => {
				const wavyTextTL1 = gsap.timeline()
					.from(element.querySelector('#wavy-text-path-1'), {
						attr: { startOffset: '100%' },
						ease: 'power1'
					})
					.duration(2);


				const wavyTextTL2 = gsap.timeline()
					.from(element.querySelector('#wavy-text-path-2'), {
						attr: { startOffset: '100%' },
						ease: 'power1'
					})
					.duration(2);

				window.setTimeout(wavyTextTL1.play, 500);
				window.setTimeout(wavyTextTL2.play, 800);

				animations.push(wavyTextTL1, wavyTextTL2);
			},
			'(min-width: 801px)': () => {
				bubbleVideo.current.playbackRate = 0.5;

				const triggerVideo = async video => {
					if (!video.current) return;
					try {
						await video.current.pause();
						video.current.currentTime = 0;
						await video.current.play();
					} catch (error) {
						console.log(error);
					}
				}

				animations.push(gsap.to(foodOneVideo.current, {
					scrollTrigger: {
						trigger: element.querySelector('.js-cloud-section-1'),
						start: 'top bottom',
						end: 'bottom top',
						onToggle: () => triggerVideo(foodOneVideo),
						end: 'bottom top',
					},
				}))

				const wavyTextTL1 = gsap.timeline()
					.from(element.querySelector('#wavy-text-path-1'), {
						attr: { startOffset: '100%' },
						ease: 'power1',
						onComplete : () => wavyTextTL1.kill(),
					})
					.duration(2);


				const wavyTextTL2 = gsap.timeline()
					.from(element.querySelector('#wavy-text-path-2'), {
						attr: { startOffset: '100%' },
						ease: 'power1',
						onComplete : () => wavyTextTL1.kill(),
					})
					.duration(2);
				
				window.setTimeout(() => {
					wavyTextTL1.play()
				}, 500);
				window.setTimeout(() => {
					wavyTextTL2.play()
				}, 800);

				animations.push(wavyTextTL1, wavyTextTL2);

				animations.push(gsap.to(cloudVideo.current, {
					scrollTrigger: {
						trigger: element.querySelector('.js-two-up-1'),
						start: 'center center',
						onToggle: () => triggerVideo(cloudVideo),
						end: 'bottom top',
					},
				}))

				animations.push(gsap.to(bloomVideo.current, {
					scrollTrigger: {
						trigger: element.querySelector('.js-two-up-3'),
						start: 'center center',
						onToggle: () => triggerVideo(bloomVideo),
						end: 'bottom top',
					},
				}))

				animations.push(gsap.to(bubbleVideo.current, {
					scrollTrigger: {
						trigger: element.querySelector('.js-two-up-2'),
						start: 'center center',
						onToggle: () => triggerVideo(bubbleVideo),
						end: 'bottom top',
					},
				}))

				animations.push(gsap.to(airFoodVideo.current, {
					scrollTrigger: {
						trigger: element.querySelector('.js-two-up-3'),
						start: 'center center',
						onToggle: () => triggerVideo(airFoodVideo),
						end: 'bottom top',
					},
				}))

				const wavyTextTL3 = gsap.timeline()
					.from(element.querySelector('#wavy-text-path-3'), {
						attr: { startOffset: '100%' },
						ease: 'power1'
					})
					.duration(2);

				ScrollTrigger.create({
					trigger: element.querySelector('.js-wavy-text-3'),
					animation: wavyTextTL3,
					start: 'top bottom',
					end: 'center center',
					scrub: true,
				});

				animations.push(wavyTextTL3);

				animations.push(gsap.to(element.querySelector('.js-diagonal-food'), {
					scrollTrigger: {
						trigger: element.querySelector('.js-cloud-section-2'),
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
					},
					left: '40%',
					top: '50%',
				}))
			},
		})

		return function cleanup() {
			animations.forEach(a => { try { a.kill() } catch (error) { console.log(error) } })
		}

	}, [ref])

	return (
		<div className="page-process posr" ref={ref} >
			<Helmet>
				<title>Air Protein | Making Protein from Air</title>
				<meta name="description" content="How we&rsquo;ll feed the future. Our recipe for change. Meet the new protein"></meta>
				<meta name="keywords" content="Meat made from air, air meat, air chicken, air scallop, air fish fillet, Fermentation, plant based meat, future of meat"></meta>
			</Helmet>
			
			<div className="w100% pl100 pr100 ml-auto mr-auto rev-wrap"
			>
			{/* <div className="w100% pl100 pr100 ml-auto mr-auto rev-wrap"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/process/revolution-sky.jpg)',
				backgroundSize: 'cover'
			}}
			> */}
			<div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section1 revolution">
        <div className="home-section1-half">
        <h1 className='rev-food'>Helping to Achieve Scope 3 Emissions Targets</h1>
		<h1 class="home-intro-h1 logoHome">Better Ingredients. Produced in a Better Way. Better than Carbon Neutral.</h1>
        </div>
        <div className="home-section1-half">
				<img className="responsive revolution-hands" src="https://d172nc0scfm542.cloudfront.net/process/bowl-powder-bluberries.png" />
          </div>
</div>
		</div>


			<div className="js-orange-section-1 bgc-crusta c-white">
				<Grid className="js-orange-section-1-content h100vh hu@md hu@lgxx-mn hu@lgxx-mn pt6rem@lgxx-mn pb6rem@lgxx-mn">
					<div
						className="posr h100vh mih0vh hu@md hu@lgxx-mn"
						style={{
							fontSize: 'clamp(18px, 3.85vw, 53px)',
						}}
					>
						<div className="posr center-vert un-center-vert@md  un-center-vert@lgxx-mn pt3rem@md pb3rem@md w80%">
							<p style={{ marginBottom: '4vh' }}>
								We believe climate change and food scarcity can be reduced by reimagining food creation.
							</p>
							<p>
								Our groundbreaking process is carbon negative, massively scalable, and can happen virtually anywhere.
							</p>
						</div>
					</div>
				</Grid>
			</div>

			<div className="bgc-white">
				<div className="js-large-heading-1 c-crusta h50vh hu@md hu@lgxx-mn">
					<Grid className="center-vert  un-center-vert@md  un-center-vert@lgxx-mn pt3rem@md posr pt6rem@lgxx-mn pb6rem@lgxx-mn">
						<h2 className="change-header w80% mih0vh w100%@md">
							Our Recipe<br />for Change
						</h2>
					</Grid>
				</div>

				<div className="js-two-up-1 h75vh mb1rem@md hu@md hu@lgxx-mn">
					<Grid className="center-vert un-center-vert@md  un-center-vert@lgxx-mn pt3rem@md pb3rem@md posr df fxdrc@md jcc@md aic@md">
						<div className="dn db@md w100% maw300 m-auto">
							<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/process/process-1.png" />
						</div>
						<div className="w50% w100%@md">
							<div className="df">
								<div className="step-number w10vw fxg0 fxsh0 mr1rem">
									<svg className="w90%" xmlns="http://www.w3.org/2000/svg" viewBox="130 15 125 100"><defs><style dangerouslySetInnerHTML={{ __html: `.cls-1{font-size:132.71px;fill:none;stroke:#f57f38;stroke-width:1.2px;font-family:EuclidCircularA-Regular, EuclidCircularA Regular;letter-spacing:0.03em;}` }}></style></defs><text className="cls-1" transform="translate(131.81 111.55)">01</text></svg>
								</div>
								<div className="step-block 10vh">
									<h3 className="step-headline mih0vh ff-ecl mb1.5rem">
									AirFermentation<tm>TM</tm>
									</h3>
									<p className="step-copy w70% w90%@md fz1.125rem">
									Our process begins with AirFermentation, using the same building blocks that all plant life needs to grow–air, water, and renewable energy.
									</p>
								</div>
							</div>
						</div>
						<div className="w50% db dn@md">
							{window.innerWidth > 800 && <video
								className="video-step video-step-1 posa r0"
								ref={cloudVideo}
								muted={true}
								playsInline={true}
								loop={false}
							>
								<source src="https://d172nc0scfm542.cloudfront.net/video/AP_01_16-10.webm" type="video/webm" />
							</video>}
						</div>
					</Grid>
				</div>

				<div className="js-two-up-2 posr h75vh hu@md hu@lgxx-mn mb1rem@md">
					<Grid className="center-vert un-center-vert@md  un-center-vert@lgxx-mn pb3rem@md posr df fxdrc@md jcc@md aic@md">
						<div className="dn db@md w100% maw300 m-auto">
							<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/process/process-2.png" />
						</div>
						<div className="w50% w100%@md db dn@md">
							{window.innerWidth > 800 && <video
								className="video-step video-step-2 posa l50"
								ref={bubbleVideo}
								muted={true}
								playsInline={true}
								loop={false}
							>
								<source src="https://d172nc0scfm542.cloudfront.net/video/bubbles.mp4" type="video/mp4" />
							</video>}
						</div>
						<div className="w50% w100%@md z10">
							<div className="df">
								<div className="step-number w10vw fxg0 fxsh0 mr1rem">
									<svg className="w100%" xmlns="http://www.w3.org/2000/svg" viewBox="130 15 157 100"><defs><style dangerouslySetInnerHTML={{ __html: `.cls-1{font-size:132.71px;fill:none;stroke:#f57f38;stroke-width:1.2px;font-family:EuclidCircularA-Regular, EuclidCircularA Regular;letter-spacing:0.03em;}` }}></style></defs><text className="cls-1" transform="translate(131.81 111.55)">02</text></svg>
								</div>
								<div className="step-block 10vh">
									<h3 className="step-headline mih0vh ff-ecl mb1.5rem">
										They Grow Up<br />So Fast
									</h3>
									<p className="step-copy w70% w90%@md fz1.125rem">
										Elements of the air are whisked together with our cultures until they produce protein within a matter of hours&ndash;a process similar to how yogurt, cheese, and wine are made.
									</p>
								</div>
							</div>
						</div>
					</Grid>
				</div>

				<div className="js-two-up-3 h75vh hu@md hu@lgxx-mn mb8rem mb1rem@md">
					<Grid className="center-vert un-center-vert@md  un-center-vert@lgxx-mn pb3rem@md posr df fxdrc@md jcc@md aic@md">
						<div className="dn db@md w100% maw300 m-auto">
							<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/process/process-3.png" />
						</div>
						<div className="w50% w100%@md posr z1 mb8rem@lgxx-mn">
							<div className="df">
								<div className="step-number w10vw fxg0 fxsh0 mr1rem">
									<svg className="w100%" xmlns="http://www.w3.org/2000/svg" viewBox="130 15 160 100"><defs><style dangerouslySetInnerHTML={{ __html: `.cls-1{font-size:132.71px;fill:none;stroke:#f57f38;stroke-width:1.2px;font-family:EuclidCircularA-Regular, EuclidCircularA Regular;letter-spacing:0.03em;}` }}></style></defs><text className="cls-1" transform="translate(131.81 111.55)">03</text></svg>
								</div>
								<div className="step-block 10vh">
									<h3 className="step-headline mih0vh ff-ecl mb1.5rem">
										Reaping the<br />Harvest
									</h3>
									<p className="step-copy w70% w90%@md fz1.125rem">
										The protein that the cultures produce is harvested and purified, then dried to remove water. The result is a super-clean, protein-packed flour—nutritious, versatile, and ready to be turned into&nbsp;any&nbsp;food.
									</p>
								</div>
							</div>
						</div>
						<div className="w50% w100%@md posr z0 db dn@md">
							{window.innerWidth > 800 && <video
								className="video-step video-step-3 posa r0"
								ref={bloomVideo}
								muted={true}
								playsInline={true}
							>
								<source src="https://d172nc0scfm542.cloudfront.net/video/harvest-720-60.webm" />
							</video>}
						</div>
					</Grid>
				</div>

				<div className="js-two-up-4 h75vh hu@md hu@lgxx-mn mb8rem mb0@md">
					<Grid className="center-vert un-center-vert@md  un-center-vert@lgxx-mn posr z0  df fxdrc@md jcc@md aic@md">
						<div className="dn db@md w100% maw300 m-auto">
						<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/process/air-protein-chef-prep.jpg" />
						</div>
						<div className="w50%  db dn@md">
						<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/process/air-protein-chef-prep.jpg" />
						</div>
						<div className="w50% w100%@md posr z1 mb3rem">
							<div className="df">
								<div className="step-number w10vw fxg0 fxsh0 mr1rem">
									<svg className="w100%" xmlns="http://www.w3.org/2000/svg" viewBox="130 15 166 100"><defs><style dangerouslySetInnerHTML={{ __html: `.cls-1{font-size:132.71px;fill:none;stroke:#f57f38;stroke-width:1.2px;font-family:EuclidCircularA-Regular, EuclidCircularA Regular;letter-spacing:0.03em;}` }}></style></defs><text className="cls-1" transform="translate(131.81 111.55)">04</text></svg>
								</div>
								<div className="step-block 10vh">
									<h3 className="step-headline mih0vh ff-ecl mb1.5rem">
									From Flour to <br/>Ingredients to Foods
									</h3>
									<p className="step-copy w70% w90%@md fz1.125rem">
									Finally, the flour is processed to create a range of functional ingredients with flavors, tastes, textures and nutritional attributes suitable for a broad array of categories from Dairy to Meat to Baked Goods and beyond.
									</p>
								</div>
							</div>
						</div>
					</Grid>
				</div>
			</div>

			



			
		</div>
	)
}
export default Process;
