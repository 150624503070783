import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import { Helmet } from "react-helmet";

import Grid from "../../blocks/Grid";
import mobileTabletCheck from "../../util/mobile-tablet-check";

import logoAnimation from "../../animation/air-protein-logo.json";
import Logo from '../../components/Logo';

import Section1 from "./partials/Section1";
import Section2 from "./partials/Section2";
import Section3 from "./partials/Section3";
import Section4 from "./partials/Section4";
import Section5 from "./partials/Section5";
import Section6 from "./partials/Section6";
import Section7 from "./partials/Section7";

function Modal(props) {
  const { isOpen, onClose, children } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        {children}
      </div>
    </div>
  );
}

const Home = () => {
  const ref = useRef(null);
  const chickenSaladVideo = useRef(null);
  const [introPlayed, setIntroPlayed] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!introPlayed) {
    window.setTimeout(() => {
      const header = document.querySelector("header.header");
      // try to force to top of screen and prevent scrolling
      window.scroll(0, 0);
      window.scrollTo(0, 0);
      const app = document.querySelector("#app");
      app.style.height = "100vh";
      app.style.overflow = "hidden";

      // hide the header
      header.style.visibility = "hidden";
      header.style.opacity = "0";
      header.style.transition = "1s opacity ease-in";

      // lottie.loadAnimation({
      //   container: ref.current.querySelector(".js-logo-container"),
      //   animationData: logoAnimation,
      // });

      window.setTimeout(() => {
        header.style.visibility = "visible";
        header.style.opacity = "1";
      }, 30);

      window.setTimeout(() => {
        window.scroll(0, 0);
        window.scrollTo(0, 0);
        app.style.height = "auto";
        app.style.overflow = "";
        window.scroll(0, 0);
        setIntroPlayed(true);
      }, 50);
      // setIntroPlayed(true);
    }, 0);
  }

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    if (chickenSaladVideo.current)
      chickenSaladVideo.current.playbackRate = 0.75;

    if (!introPlayed) {
      gsap
        .timeline()
        .delay(0)
        .to(element.querySelector(".js-air-protein-chicken"), {
          opacity: "1",
          duration: 1,
          top: "20vh"
        });

      let airProteinLogoAnimation = gsap
        .timeline()
        .delay(3)
        .to(element.querySelector(".js-air-protein-logo"), {
          top: "0",
          opacity: "0",
          duration: 6,
        })
        .to(element.querySelector(".js-air-protein-logo"), {
          display: "none",
        });

      let textAnimation;
      ScrollTrigger.matchMedia({
        "(min-width: 801px)": () => {
          textAnimation = gsap
            .timeline()
            .delay(0)
            .to(element.querySelector(".js-hero-text"), {
              duration: 3,
              top: "32vh",
              opacity: "2",
            });
        },
        "(min-width: 601px) and (max-width: 800px)": () => {
          textAnimation = gsap
            .timeline()
            .delay(3.5)
            .to(element.querySelector(".js-hero-text"), {
              duration: 1,
              top: "20vh",
              opacity: "2",
            });
        },
        "(min-width: 451px) and (max-width: 600px)": () => {
          textAnimation = gsap
            .timeline()
            .delay(3.5)
            .to(element.querySelector(".js-hero-text"), {
              duration: .1,
              top: "16vh",
              opacity: "2",
            });
        },
        "(max-width: 450px)": () => {
          textAnimation = gsap
            .timeline()
            .delay(3.5)
            .to(element.querySelector(".js-hero-text"), {
              duration: 3,
              top: "50vh",
              opacity: "2",
            });
        },
      });

      const airFoodAnimation = gsap.to(
        element.querySelector(".js-air-protein-chicken"),
        {
          scrollTrigger: {
            trigger: element.querySelector(".js-air-protein-chicken"),
            start: "10vh top",
            onToggle: (self) => {
              const vid = element.querySelector(".js-air-protein-chicken");
              if (!vid || !vid.currentTime) return;
              vid.currentTime = 0;
              vid.play();
            },
          },
        }
      );      
    }

    return function cleanup() {
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, [introPlayed]);

  return (
    <div className="page-home" ref={ref}>
      <Helmet>
        <title>Air Protein | Protein made from Air</title>
        <meta
          name="description"
          content="We’re crafting the world’s first air protein. For a variety of applications and processes. Turning elements in the air into sustainable protein that eliminates compromise between tastes, nutrition and climate threat. Hear from our Founder. Join the movement. Eat for the world you want."
        ></meta>
        <meta
          name="keywords"
          content="Air protein, Alternative protein, Protein made from air, Carbon negative, sustainable protein alternative, future of protein"
        ></meta>
      </Helmet>
      


    <div className="boxSky homeGirl">
        <img className="desktop-only responsive home-intro-banner-girl" src="https://d172nc0scfm542.cloudfront.net/home/girl.jpg" />
        <img className="mobile-only responsive home-intro-banner-girl" src="https://d172nc0scfm542.cloudfront.net/home/girl-mobile.jpg" />
        <h1 className='mobile-only ff-ec posr mih0vh fz-clamp(28,4vw,66) lh1.2 w65%@md center-hori@md w100%@sm'>We Believe You Can Eat For The World You Want</h1>
        <div className="mobile-only responsive home-intro-logo">
        <Logo />
        </div>
   </div>

    
   <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section2">


<div className="home-section1-half rightSet mob-order-0">
    <h1 className="mySize ff-ec posr mih0vh fz-clamp(28,4vw,66) lh16 w65%@md center-hori@md w100%@sm">Air Protein</h1>
    <h2>A versatile super protein whose time has come</h2>
    <p className="w55% introText">Nutrient-dense, affordable food ingredients and better than carbon neutral.</p>
    <p className="w55% introText">&nbsp;</p>
    <p className="w55% introText">We’ve unlocked nature’s ultimate protein, grown with air, water, and energy – defining the future of wholesome food production through landless agriculture.</p>
    </div>

    <div className="home-section1-half mob-order-1">
    <img className="responsive" src="https://d172nc0scfm542.cloudfront.net/home/globe-powder.png" />
      </div>
</div>
      {introPlayed && (
        <>
          {/* <Section1 /> */}
          {/* <Section2 /> */}
          <Section3 />
          {/* <Section4 />
          <Section5 /> */}
          {/* <Section6 /> */}
          <Section7 />
        </>
      )}
    </div>
  );
};

export default Home;
