import { gsap } from "gsap";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Grid from "../blocks/Grid";
import ButtonCTA from "../components/ButtonCTA";
import ManiVideo from "./About/ManiVideo";
import LogoWhite from '../components/LogoWhite'

const About = () => {
  useEffect(() => {
    let tl, tl2;
    setTimeout(() => {
      const trigger = ".moonshot";
      let delayObject = {
        idx: 0,
      };
      tl2 = gsap.timeline();
      tl2
        .to(".mc-copy div", {
          opacity: "100%",
        });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: trigger,
          start: "center center",
          end: "+=2500",
          scrub: 1,
          markers: false,
          pin: trigger,
        },
      });

      tl.to(".moon-window", {
        clipPath: "circle(25% at 50% 50%)",
      })
        .to(".moon-holder", {
          left: "40%",
        })
        .to(
          ".moon-title",
          {
            opacity: "100%",
          },
          "<"
        )
        .to(".moon-copy", {
          opacity: "100%",
        })
        .to(".moon-holder", {
          opacity: "0",
        })
        .add(tl2, "circleIn")
        .to(
          ".mc-icons",
          {
            opacity: "100%",
          },
          "circleIn"
        )
        .to(delayObject, {
          idx: 1000,
        })
        .to(delayObject, {
          idx: 0,
        });
    }, 250);
    return () => {
      try {
        tl.kill();
        tl2.kill();
      } catch (error) {}
    };
  }, []);
  return (
    <div className="page-about">
      <Helmet>
        <title>Air Protein | About Air Protein</title>
        <meta
          name="description"
          content="From moonshot to protein. Inspired by NASA, designed by Air Protein. Meet the team."
        ></meta>
        <meta
          name="keywords"
          content="Carbon negative food, meat alternative, air meat, plant based meat, Lisa Dyson, ADM"
        ></meta>
      </Helmet>
      <div className="intro bgc-crusta c-white">
        <div className="headline">
        Meet Air Protein
          {/* <div className="logo-inline">
							<LogoWhite />
					</div> */}
        </div>
      </div>

      {/* This is duplicated and setup for mobile below. If we fall below a breakpoint we switch to mobile. Note make copy & gfx edits in both spots */}
      <div className="moonshot df fxdrc@md aic w100% mih100vh mih50vh@md bgc-white posr dn@md">
        <div className="moon-holder db dn@md">
          <div className="moon-window" />
        </div>
        <Grid className="mt8rem@md">
          <div className="df fxdrc@md aic w100% mih100vh mih50vh@md">
            <div className="w50% w100%@md mih50vh@md df fxdrc pl2rem pr2rem pr0@md pl0@md aic jcc m-auto">
              <div className="w100% df fxdrc jcc aifs mb-items-lg">
                <div className="moon-title fz2rem fz1.5rem@md c-crusta">
                Our Moonshot to Feed the Planet
                </div>
                <div className="moon-copy fz1.25rem c-elephant">
                During the 1970s space program, NASA scientists explored a way to feed astronauts on long space journeys by transforming elements in the air the astronauts breathed into protein. This process was shelved and forgotten back then, only to be rediscovered by Dr. Lisa Dyson and Dr. John Reed and perfected by the Air Protein team.
                </div>
                <div className="dn db@md">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/closed-carbon-loop.png"
                  />
                </div>
              </div>
            </div>
            <div className="w50% w100%@md">
              <div className="moon-circle ">
                <div className="mc-copy tac fz2rem fz1rem@md">
                  <div className="mc-copy-line-1 ff-ecl"></div>
                  <div className="mc-copy-line-3 ff-ecl"></div>
                  <div className="mc-copy-line-4 ff-ecl"></div>
                </div>
                <div className="mc-icons">
                  <div className="mc-circle" />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <div className="moonshot-mobile fxdrc aic w100% mih100vh mih50vh@md bgc-white posr dn df@md">
        <div className={`mb1.5rem@md`}>
          <img
            className="responsive"
            src="https://d172nc0scfm542.cloudfront.net/about/moon-window.jpg"
          />
        </div>
        <Grid className="">
          <div className="df fxdrc@md aic w100% mih100vh mih50vh@md">
            <div className="w50% w100%@md mih50vh@md df fxdrc pl2rem pr2rem pr0@md pl0@md aic jcc m-auto">
              <div className="w100% df fxdrc jcc aifs mb-items-lg">
                <div className="fz3.25rem fz1.5rem@md c-crusta">
                  From Moonshot to Meat
                </div>
                <div className="fz1.25rem c-elephant">
                  During the 1970s space program, NASA scientists explored a way
                  to feed astronauts on long space journeys using carbon
                  transformation. This process was shelved and forgotten back
                  then, only to be rediscovered by Dr. Lisa Dyson and Dr. John
                  Reed and perfected by the Air Protein team. Combined with
                  modern food-making technology, we’re delivering the most
                  sustainable meat alternative on earth.
                </div>
                <div className="">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/closed-carbon-loop.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>




      <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section1">
       <h1 className="timeline-headline">Air Protein Timeline</h1>
      </div>
      <div className={`mb3rem db dn@md maw1400 m-auto`}>
        <img
          className="responsive"
          src="https://d172nc0scfm542.cloudfront.net/about/timeline-desktop.webp"
        />
      </div>
      <div className={`mb3rem dn db@md`}>
        {/* Use this img for mobile */}
        <img
          className="responsive"
          src="https://d172nc0scfm542.cloudfront.net/about/timeline-mobile.webp"
        />
      </div>
      <div className="team-blue">
        <Grid>
          <div className="sub-title c-white lts1.72">Meet the Founders</div>
          <div className="df jcc">
            <div className="blue-team-member df aic jcsb fxdrc@md mb3rem maw300@md jcc@md tac@md">
              <div className="maw400 w100%@md mb1.5rem@md">
                <div className="mr1.5rem">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/ldyson.png"
                  />
                </div>
              </div>
              <div className="w50% w100%@md">
                <div className="c-white fz2.5rem fz1.5rem@md">
                  Dr. Lisa Dyson
                </div>
                <div className="c-white fz1.5rem fz1rem@md mb1rem">
                  Founder, CEO &amp; Director
                </div>
                <div className="tal@md">
                  Dr. Lisa Dyson is a mission-driven entrepreneur with a passion
                  for creative problem solving. Working to help
                  the people of New Orleans after Hurricane Katrina, she
                  witnessed first-hand the devastation caused by weather-related
                  disasters and has since been on a mission to address climate
                  change. Lisa has a PhD in Physics from MIT, and is a Fulbright
                  Scholar from the University of London where she received an MS
                  in Physics. She is a researcher and scientist at heart,
                  working on projects in Bioengineering and Physics at Stanford
                  University; the University of California, Berkeley; Princeton
                  University, the University of California, San Francisco, the
                  Massachusetts Institute of Technology (MIT), and the Lawrence
                  Berkeley National Laboratories. Her background paved the way
                  for her to see through the devastation we are experiencing on
                  the planet and find a way to feed us sustainably.{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="df jcc">
            <div className="blue-team-member df aic jcsb fxdrc@md maw300@md jcc@md tac@md">
              <div className="maw400 w100%@md mb1.5rem@md">
                <div className="mr1.5rem">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/jreed.png"
                  />
                </div>
              </div>
              <div className="w50% w100%@md">
                <div className="c-white fz2.5rem fz1.5rem@md">
                  Dr. John Reed
                </div>
                <div className="c-white fz1.5rem fz1rem@md mb1rem">
                  Co-Founder and Chief Scientific Advisor
                </div>
                <div className="tal@md">
                Dr. Reed is an expert in chemical and biological energy storage and conversion technologies. He is also one of the world’s leading experts on air-based technologies and has over 20 years of experience in the research and development of clean energy technologies. He has a strong multidisciplinary background in a number of scientific disciplines including biology, physics, material science and applied mathematics. He earned a PhD and an MS degree in Material Science and Engineering from the Massachusetts Institute of Technology as well as degrees in Biology and Material Science from UC Berkeley. Dr. Reed is an inventor who holds dozens of patents. He has developed a unique approach to carbon mitigation that is a hybrid chemical and microbial process for the capture and recycling of inorganic carbon into high energy density biofuel and chemicals using conventional bioreactors without reliance on food-based agriculture.{" "}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <div className="team-orange">
        <Grid>
          <div className="sub-title c-crusta w100% tac">
            Air Protein Board of Directors
          </div>
          {/* <div className="df jcc">
            <div className="df aic jcsb jcc@md fxdrc@md mb3rem maw300@md jcc@md tac@md">
              <div className="maw400 w100%@md mb1.5rem@md pl1.5rem pl0@md">
                <div className="mr1.5rem">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/jwhite.png"
                  />
                </div>
              </div>
              <div className="w50% w100%@md">
                <div className="c-crusta fz2.5rem fz1.5rem@md">
                  James D. White
                </div>
                <div className="c-crusta fz1.5rem fz1rem@md mb1rem">
                  Executive Chair
                </div>
                <div className="tal@md">
                  James White is a transformational leader with more than 30
                  years experience as an operating executive in the Consumer
                  Products, Retail, and Restaurant industries and 20 years in
                  the board leadership and service. He is the former Chairman,
                  President and CEO of Jamba Juice, where he led the successful
                  turnaround and transformation of the company from a
                  made-to-order smoothie shop to a global, healthy active
                  lifestyle brand.
                </div>
              </div>
            </div>
          </div> */}
          <div className="df fxwww fxdrc@md jcc@md aic@md">
            <div className="orange-team-member df aic jcfs fxdrc m3rem w50% w100%@md">
              <div className="maw175 mb1.5rem@md">
                <div className="mb-items-md">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/jgrundfest.png"
                  />
                </div>
              </div>
              <div className="w100% mb-items-md tac">
                <div className="fz1.25rem">Joseph A. Grundfest</div>
                <div className="tal@md">
                  Joe is the William A. Franke Professor of Law and Business at
                  Stanford Law School, and a former Commissioner of the United
                  States Securities and Exchange Commission. He chairs the audit
                  committee at KKR, Inc., co-founded Financial Engines with Bill
                  Sharpe, the 1990 Nobelist in Economics, and has been active in
                  early-stage investing. Joe is a nationally recognized expert
                  in corporate governance, securities litigation, and a range of
                  business law matters. He's been recognized as among America's
                  leading attorneys, and his scholarship has been published in
                  the Harvard, Yale, and Stanford law reviews.{" "}
                </div>
              </div>
            </div>
            <div className="orange-team-member df aic jcfs fxdrc m3rem w50% w100%@md">
              <div className="maw175 mb1.5rem@md">
                <div className="mb-items-md">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/mganzler.png"
                  />
                </div>
              </div>
              <div className="w100% mb-items-md tac">
                <div className="fz1.25rem">Maisie Ganzler</div>
                <div className="tal@md">
                  As Chief Strategy &amp; Brand Officer, Maisie Ganzler has been
                  instrumental in shaping the overall strategic direction of the
                  food service pioneer Bon Appétit Management Company for over
                  two decades, overseeing Bon Appétit’s strategic initiatives,
                  culinary development, purchasing, and more. In 1999, she
                  helped develop the groundbreaking Farm to Fork
                  local-purchasing program and has since launched many of Bon
                  Appétit’s other progressive initiatives in the areas of animal
                  welfare, sustainable seafood, antibiotics, farmworker rights,
                  and food waste. More recently, she has focused on antibiotics
                  in agriculture and aquaculture, plant-forward innovation, and
                  the development of a proprietary kitchen waste-tracking tool.
                </div>
              </div>
            </div>
            <div className="orange-team-member df aic jcfs fxdrc m3rem w50% w100%@md">
              <div className="maw175 mb1.5rem@md">
                <div className="mb-items-md">
                  <img
                    className="responsive"
                    src="https://d172nc0scfm542.cloudfront.net/about/osasson.png"
                  />
                </div>
              </div>
              <div className="w100% mb-items-md tac">
                <div className="fz1.25rem">Ori Sasson</div>
                <div className="tal@md">
                  Ori is the founding General Partner of Primera Capital and has
                  one of the best investment records in the industry. He has
                  chaired companies in the wireless carrier services, global
                  transportation logistics and managed services spaces. Ori has
                  experienced tremendous success leading start-up and
                  established high technology companies in all stages of their
                  business.{" "}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>




      




    </div>
  );
};

export default About;
