import React, { useState, useEffect, useRef } from 'react';
import FAQItem from '../components/FAQItem';
import LoadingAnimation from '../components/LoadingAnimation';
import {Helmet} from "react-helmet";


const Resources = () => {
	const ref = useRef(null)
	const [checked, setChecked] = useState(-1)
	const [downloads, setDownloads] = useState(null)
	const [faqs, setFaqs] = useState(null)
	const [errors, setErrors] = useState({ downloads: null, faqs: null })
	!errors.downloads && !downloads && fetch('/api/downloads').then(resp => resp.json().then(x => setDownloads(x))).catch(err => setErrors({ downloads: err, faqs: errors.faqs }))
	!errors.faqs && !faqs && fetch('/api/faqs').then(resp => resp.json().then(x => setFaqs(x))).catch(err => setErrors({ faqs: err, downloads: errors.downloads }))
	const faqItems = items => {
		return items.map((x, i) =>
			<FAQItem
				key={x._id}
				index={i}
				title={x.name}
				copy={x.description}
				checked={checked}
				setChecked={setChecked}
			/>

		)
	}
	const downloadItems = items => {
		return items.map((x, i) =>
			<div key={x._id} className="mb1.5rem">
				<div className="df jcsb aic fxdrc@md">
					<div className="fz1.25rem ff-ecl mb1.5rem">{x.name}</div>
					<div className="c-crusta mb1.5rem"><a download className="dib tdn c-elephant bd3-s-elephant bdrs25 pl25 pr25 pt10 pb10" href={x.download ? x.download.url : `#`}>DOWNLOAD</a></div>
				</div>
				{!(i == items.length - 1) && <div className="bgc-crusta h2 w100%"></div>}
			</div>
		)
	}

	useEffect(() => {
		let handle
		const makeSticky = evt => {
			if (ref.current) {
				ref.current.style.marginTop = Math.floor(Math.min((window.scrollY + 65), document.querySelector('.faq-holder').clientHeight - window.innerHeight)) + 'px'
			}
			handle = window.requestAnimationFrame(makeSticky)
		}
		if (ref.current) {
			handle = window.requestAnimationFrame(makeSticky)
		}
		return () => {
			try {
				window.cancelAnimationFrame(handle)
			} catch (error) {
				console.log(error)
			}
		}
	}, [ref])

	return <div className="page-resources maw100%">
		<Helmet>
			<title>Air Protein | FAQ and Resources</title>
			<meta name="description" content=""></meta>
			<meta name="keywords" content=""></meta>
		</Helmet>
		<div className="faq-holder df fxdrc@md aic@md  w100% bgc-white">
			<div className="faq-items w40% w100%@md df fxdrc pl2rem pr2rem pt4rem pb4rem m-auto">
				<div className="fz2rem c-crusta mb4rem">FAQ</div>
				<div className="mb4rem">
					{faqs &&
						faqItems(faqs)
					}
					{!faqs && !errors.faqs &&
						<LoadingAnimation />
					}
					{errors.faqs &&
						<p>Error loading content</p>
					}
				</div>
				{/* <div className="fz2rem c-crusta mb4rem">Resources</div> */}
				{/* <div className="">
					{downloads &&
						downloadItems(downloads)
					}
					{!downloads && !errors.downloads &&
						<LoadingAnimation />
					}
					{errors.downloads &&
						<p>Error loading content</p>
					}
				</div> */}
			</div>
			<div className="dn@md w50% w100%@md h100vh h75vh@md posr">
				<div className="dg h100vh w100% faq-image"
					ref={ref}
					style={{
						placeItems: 'center center',
						backgroundColor: '#72b0cf',
						backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/faq/faq-bg.jpeg)',
						backgroundSize: 'cover',
						// position: 'sticky',
						// top: '0'
					}}
				>
					<div className="faq-food" >
						<div  >
							<img className="responsive posr" src="https://d172nc0scfm542.cloudfront.net/faq/faq-food.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default Resources;
