import React, { useState } from "react";
import OrangeRadio from "./OrangeRadio";

const FAQItem = ({title, copy, index, checked, setChecked}) => {

    return <div className="mb1.5rem posr">
        <div className="posa t0 r0 curp">
            <OrangeRadio checked={checked === index} index={index} setChecked={setChecked} name={`faq`} />
        </div>
        <div className="fz1.25rem ff-ecl mb1.5rem mr4rem curp" onClick={ _=> setChecked(checked === index ? -1 : index)}>{title}</div>
        {checked === index && <div className="faq-body lh1.5 c-crusta mb1.5rem mb-items-md" dangerouslySetInnerHTML={{__html:copy}}></div>}
        <div className="bgc-crusta h2 w100%"></div>
    </div>
}

export default FAQItem