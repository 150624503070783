import React, { useRef, useState, useEffect } from 'react';

import mobileTabletCheck from '../util/mobile-tablet-check';

export default function LandscapeModeWarning() {
	const warningWindow = useRef(null);
	const [ orientationSwapped, setOrientationSwapped ] = useState((window.screen.height > window.screen.width) ? 0 : 1);

	useEffect(() => {
		const landscapeModeWarning = (e, init) => {
			warningWindow.current.style.display = (window.screen.height > window.screen.width) ? 'none' : 'block';

			if(init) return;

			if(orientationSwapped >= 2) {
				window.location.href = window.location.href;
				return;
			}

			setOrientationSwapped(orientationSwapped+1);
		}
		if(mobileTabletCheck()) {
			window.addEventListener('orientationchange', landscapeModeWarning);
			landscapeModeWarning({}, true);
		}
		return function cleanup() {
			window.removeEventListener('orientationchange', landscapeModeWarning);
		}
	}, [ warningWindow, orientationSwapped ])

	return (
	<div className="landscape-mode-warning posf t0 l0 h100vh w100vw z101 dn bgc-white" ref={warningWindow}>
		<div className="posa center fz5vw w80%">
			Please orient your device in portrait mode.
		</div>
	</div>
	)
}