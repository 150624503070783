import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FounderVideo from "./FounderVideo";

import Grid from "../../../blocks/Grid";
import { Link } from "react-router-dom";

const playBloom = () => {
  const bloom = document.querySelector('.js-bloom-animation');
  if(bloom) {
    bloom.currentTime = 0;
    bloom.play();
  }
}
const playEarth = () => {
  const earth = document.querySelector('.earth-animation');
  if(earth) {
    earth.currentTime = 0;
    earth.play();
  }
}
const pauseEarth = () => {
  const earth = document.querySelector('.earth-animation');
  if(earth) {
    earth.currentTime = 0;
    earth.play();
  }
}
const pauseBloom = () => {
  const bloom = document.querySelector('.js-bloom-animation');
  if(bloom)
    bloom.pause();
}

const playResources = () => {
  const resourceVid = document.querySelector('.js-less-resources-video');
  if(resourceVid) {
    resourceVid.currentTime = 0;
    resourceVid.play();
  }
}
const pauseResources = () => {
  const resourceVid = document.querySelector('.js-less-resources-video');
  if(resourceVid)
    resourceVid.pause();
}


const Section3 = () => {
  const ref = useRef(null);
  const [activeMenu, setActiveMenu] = useState("nutritious");

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    gsap.to('.js-snap-section-1-content', {
      scrollTrigger: {
        trigger: ".js-snap-section-1",
        start   : `-200px`,
        end     : `${ window.innerHeight }px center`,
        scrub   : true,
        pin     : false,
        invalidateOnRefresh : true,
        onToggle : ({ isActive, direction }) => {
          if(isActive)
            playBloom();
          else if(direction === -1)
            pauseBloom();
        },
},
    });

    gsap.to('.js-snap-section-2-content', {
      scrollTrigger: {
        trigger: ".js-snap-section-2",
        start   : `-200px`,
        end     : `${ window.innerHeight }px center`,
        scrub   : true,
        pin     : false,
        invalidateOnRefresh : true,
        onToggle : ({ isActive, direction }) => {
          if(isActive){
            playResources();
            playEarth();
          } else if(direction === -1) {
            pauseResources();
            pauseEarth();
          }
        },
},
    });

    ScrollTrigger.matchMedia({
      "(min-width: 801px)": () => {
      },
      "(max-width: 800px)": () => {
      },
    });

    return function cleanup() {
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  const textShadowValues =
    "-1px -1px 0 #FF8033, 1px -1px 0 #FF8033, -1px 1px 0 #FF8033, 1px 1px 0 #FF8033";

  return (
    <>


      <div className="js-snap-section-2 carbon-negative-wrapper backFix">
        <div className="js-snap-section-2-content carbon-negative-container rowFix">

           <div className="right">
                <FounderVideo />
            </div>

         
          <div className="left">
            
            <div className="description">

            <div className="heading">
            Food from Air
            </div>
            <div className="description">
            <p>We can make food that pushes the boundaries of what we thought possible. We can build a truly sustainable relationship between our food and our planet. It is not a dream. We can do it.<br></br>Yes we can.</p>
            </div>
            </div>
            {/* <div className="heading">
            We Believe You Can Eat For The World You Want
            </div> */}
          </div>



        </div>
      </div>
      
      
      
            <div className="js-snap-section-2 carbon-negative-wrapper">
        <div className="js-snap-section-2-content carbon-negative-container">
          
          <div className="left">
            {/* <div className="sub-heading">Sustainability Reimagined</div> */}
            <div className="heading">
            Growing Supply Chain-Free Food
            </div>
            <div className="description">
            <p>We are scaling ingredients that are completely removed from traditional supply chains, enabling the year-round, continuous production of nutritious ingredients and foods virtually anywhere in the world with local inputs. Our technology removes the constraints of geography, seasons and supply chains on making nutritious food.</p>
            </div>
          </div>

          <div className="right">
          <video muted id="carbonVid" className="js-less-resources-video posa mah70vh@md-h b0 trf-tr(-10%,30%) w160% posr@md" preload="auto" playsinline="" loop>
              <source src="https://d172nc0scfm542.cloudfront.net/video/CarbonNegative.mov" type="video/quicktime"/>
                <source src="https://d172nc0scfm542.cloudfront.net/video/CarbonNegative.webm" type="video/webm"/>

                </video>
          </div>



        </div>
      </div>

     
<div className="fuller">
<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/home/net-zero.jpg" />
</div>


      <div className="js-snap-section-1 take-less-time-wrapper">
        <div className="js-snap-section-1-content take-less-time-container">
          

        <div className="left">
            <video muted id="flowerVid" className="js-bloom-animation posa mah70vh@md-h b0 trf-tr(-10%,30%) w160% posr@md" preload="auto" playsinline="">
              <source src="https://d172nc0scfm542.cloudfront.net/video/Flower-For-Less-Time_6-2.mov" type="video/quicktime"/>
                <source src="https://d172nc0scfm542.cloudfront.net/video/Flower-For-Less-Time_6_1.webm" type="video/webm"/>

                </video>
          </div>
          
          <div className="right">

            <div className="heading">Protein Ahead of Its Time</div>
            <div className="subHead">Our first product, Air Protein, grows exponentially faster</div>

            {/* <div className="description">
              And with a process that takes just few days to produce, it’s also
              ahead of soy’s time, chicken’s time, and beef’s time.
            </div> */}
            <div className="blocks mt-30">
              <div className="block border">
                <div className="title-1">Air Protein</div>
                <div className="title-2">grows in<br/>hours</div>
              </div>
              <div className="block extra-lm">
                <div className="title-1">Soy</div>
                <div className="title-2">1 crop<br/>per year</div>
              </div>
            </div>
            <div className="blocks">
              <div className="block">
                <div className="title-1">Produce</div>
                <div className="title-2">1-2 crops<br/>per year</div>
              </div>
              <div className="block">
                <div className="title-1">Beef</div>
                <div className="title-2">2 years</div>
              </div>
            </div>
            {/* <div className="description">
            It would take a soy farm the size of Texas to produce as much protein as it would an Air Protein farm the size of Walt Disney World. Air Protein uses only 0.8 liters of water per kg of protein vs over 15,000 liters per kg beef. Use LCA.
            </div> */}
            <Link to="/air-protein">
              <div className="btn-learn makeIt">Learn More About Air Protein</div>
            </Link>
          </div>




        </div>
      </div>

    </>
  );
};

export default Section3;

function playVideo() {
  var video = document.getElementById("storyVid");
  video.play();
}