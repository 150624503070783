import React from 'react';

import Grid from '../blocks/Grid';
import ButtonCTA from '../components/ButtonCTA';
import {Helmet} from "react-helmet";


const Careers = () => (
	<div className="page-careers">
		<Helmet>
			<title>Air Protein | Join Air Protein</title>
			<meta name="description" content="We’re growing, we’re hiring. Join our team to transform the Future of Protein"></meta>
			<meta name="keywords" content="meat made from air, Food careers, Food science positions, Food engineers career, Future of food, future of meat"></meta>
		</Helmet>
		<div className="df fxdrc@md aic@md w100% bgc-white"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/careers/top-clouds.jpeg)',
				backgroundSize: 'cover'
			}}
			>
			<div className="potReduce w100%@sm mih100vh mih100vh@md posr">
				{/* <div className="fz4rem c-white">JOIN THE TEAM</div> */}
				<Grid>
					<div className="pt8rem mb3rem"><div className="fz4rem c-white">Join Our Team</div></div>
				</Grid>

				<img className="responsive t50vh t25vh@md r5vw guyJump" src="https://d172nc0scfm542.cloudfront.net/careers/team-circle.png" />
			</div>
			<div className="w40% w100%@md df fxdrc pl2rem pr2rem pt4rem pb4rem m-auto mb-items-lg">
				<div className="fz2.25rem fz1.75rem@md c-white sizeAd margT">At Air Protein, we are creating foods that are nutritious, delicious and more sustainable.</div>
				<div className="w80% w100%@md paraAd">In addition to the chance to work with cutting edge technology to help combat climate change and work toward addressing the impending food crisis, Air Protein employees also enjoy competitive total compensation plans and a collaborative company culture. If you join us on this relentless pursuit for more, changing the world will soon feel as natural as taking a breath.</div>
				<div>
					<ButtonCTA className="tr fz0.875rem" value="SEARCH CAREERS" style={{ width: "13.125rem" }} onClick={_ => window.open('https://jobs.lever.co/airprotein', '_blank')} />
				</div>
			</div>
		</div>
		<Grid>
			<div className="quote-grid w100% mih100vh mb3rem@md mt3rem@md" >
				<div className="quote-1 mih55% df fxdrc jcsb">
					<div className="c-white fz2rem fz1.5rem@md mb3rem">"Working for Air Protein challenges my scientific curiosity of food from air, satisfies my desire of working towards a sustainable future for the next generation, and working with the best minds in the industry is an added plus!" </div>
					<div className="">
						<div className="q-name c-white fz3.25rem fz2rem@md posr">Kripa Rao</div>
						<div className="c-white fz1.25rem fz1rem@md">Head of Technical Programs and Regulatory/QFS</div>
					</div>
				</div>
				<div className="quote-2 mih55% df fxdrc jcsb">
					<div className="c-white fz2rem fz1.5rem@md mb3rem">"It’s great to be part of such an inspiring team of innovators. Everyone supports each other – scientists, technical staff, marketers, etc., and Air Protein has such a great learning environment, it broadens my potential for future career growth."</div>
					<div className="">
						<div className="q-name c-white fz3.25rem fz2rem@md posr">Rodrigo Ritter</div>
						<div className="c-white fz1.25rem fz1rem@md">Lead, IT &amp; Project Management</div>
					</div>
				</div>
			</div>
		</Grid>





		<div className="whiter df fxdrc@md aic@md w100% bgc-white topM">
		<div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2">
        <div className="home-section1-half">
		<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/careers/equal-opp-left.png" />
          </div>

        <div className="home-section1-half rightSet">
        <h2>An Equal Opportunity Employer</h2>
        <p className="w55% introText">At Air Protein, we are committed to providing equal opportunities. Because we want the absolute best people, we enthusiastically invite people of every race, color, orientation, age, gender, origin, and ability – or any other status protected by law – to apply to join our team.</p>

        <p className="w55% introText lbra">Research has shown that women and people from marginalized groups may not apply for a role if they aren't a 100% match. Please don't hold back. If you
think you will excel in this role, but perhaps you don’t tick every box, we'd appreciate the opportunity to consider your application.</p>

		</div>

		</div>
		</div>



		<div className='whiter'>
		<div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2">
        <div className="home-section1-half tuneIn perks1">
        <h2>Benefits & Perks</h2>
        <p className="w55% introText">
			<ul className='bullPin'>
<li>Competitive Health Benefits, including ancillary benefits and HSA/FSA programs</li>
<li>Unlimited PTO and Paid Holidays</li>
<li>Parental Bonding Leave</li>
<li>401k with Company Match up to
4%</li>
</ul>
		</p>
		</div>
        <div className="home-section1-half rightSet perks2">
        <h2 class="opa">.</h2>
        <p className="w55% introText">
			<ul className='bullPin'>
<li>Employee Equity</li>
<li>Employee Development</li>
<li>Stocked kitchen with snacks and
drinks</li>
<li>Weekly All-Hands Meetings</li>
</ul>
		</p>
          </div>

		</div>
		</div>



	</div>
)

export default Careers;
