import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Grid from "../../../blocks/Grid";
import mobileTabletCheck from "../../../util/mobile-tablet-check";
import once from "../../../util/once";

let s1KillAutoFlag = false;
let s1LastTop = 0;

const Section1 = () => {
  const ref = useRef(null);
  const fishVideo = useRef(null);

  const scrollToSection2 = () => {
    if (s1KillAutoFlag) return;
    const speed = 10;
    const section2 = document.querySelector(".section-2");
    if (section2 && section2.getBoundingClientRect().top > speed) {
      s1LastTop = window.scrollY + speed;
      window.scrollTo(0, s1LastTop);
      requestAnimationFrame(scrollToSection2);
    } else window.removeEventListener("scroll", killAutoScroll);
  };

  const killAutoScroll = () => {
    if (window.scrollY !== s1LastTop && window.scrollY < s1LastTop) {
      s1KillAutoFlag = true;
      window.removeEventListener("scroll", killAutoScroll);
    }
  };

  const autoScrollFn = once(() => {
    s1LastTop = window.scrollY;
    window.addEventListener("scroll", killAutoScroll);
    scrollToSection2();
  });

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    if (fishVideo.current) fishVideo.current.playbackRate = 0.75;

    ScrollTrigger.matchMedia({
      "(min-width: 801px)": () => {
        const wavyAnimation = gsap
          .timeline()
          .from(element.querySelectorAll("#wavy-text-path-1"), {
            attr: { startOffset: "100%" },
          })
          .duration(1)
          .pause();
        animations.push(wavyAnimation);

        animations.push(
          gsap.timeline().to(element.querySelector(".js-air-food-fish"), {
            scrollTrigger: {
              trigger: element.querySelector(".js-pin-fish-block"),
              start: `top top+=80%`,
              animation: wavyAnimation,
              once: true,
              toggleActions: "play none none none",
              onToggle: () => {
                const vid = fishVideo.current;
                if (vid) vid.play();
                wavyAnimation.play();
              },
            },
          })
        );
      },
      "(max-width: 800px)": () => {
        const wavyAnimation = gsap
          .timeline()
          .from(element.querySelectorAll("#wavy-text-path-1"), {
            attr: { startOffset: "100%" },
          })
          .duration(1)
          .pause();
        animations.push(wavyAnimation);

        animations.push(
          gsap.timeline().to(element.querySelector(".js-air-food-fish"), {
            scrollTrigger: {
              trigger: element.querySelector(".js-pin-fish-block"),
              start: `top top+=80%`,
              animation: wavyAnimation,
              once: true,
              toggleActions: "play none none none",
              onToggle: () => {
                const vid = fishVideo.current;
                if (vid) vid.play();
                wavyAnimation.play();
              },
            },
          })
        );
      },
    });

    animations.push(
      gsap.timeline().to(element.querySelector(".js-air-food-fish"), {
        scrollTrigger: {
          trigger: element.querySelector(".js-pin-fish-block"),
          start: `top top`,
          scrub: true,
          pin: true,
          pinSpacing: false,
          end: "bottom bottom",
          onToggle: () => setTimeout(autoScrollFn, 1500),
        },
      })
    );

    // animations.push(
    //   gsap.to(element.querySelector(".js-cloud-overlay"), {
    //     scrollTrigger: {
    //       trigger: element.querySelector(".js-section-1-end"),
    //       start: `-${window.innerHeight} bottom`,
    //       endTrigger: element.querySelector(".js-section-1-end"),
    //       end: "bottom bottom",
    //       scrub: 0.5,
    //     },
    //     bottom: "-20vh",
    //   })
    // );

    return function cleanup() {
      window.removeEventListener("scroll", killAutoScroll);
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  return (
    <div
      className="section-1 posr z0"
      ref={ref}
      style={{
        background: "#FF8033",
        backgroundImage: `url(https://d172nc0scfm542.cloudfront.net/home/bg2.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        margin: "12vw 0"
      }}
    >
      <div className="js-section-1 posr oh h275@lgx h200@md h350 bongo">
        <div className="js-section-1-start posa t0"></div>
        <div className="js-section-1-end posa b0"></div>
        <div className="js-section-1-fish-trigger posa t10vh"></div>
        <div className="js-section-1-fish-trigger-end posa t600vh t350vh@md"></div>
        {
          //
          // Wavy text section
          //
        }
        <div className="js-pin-fish-block h275@lgx h200@md h350 maw1400">
          <div className="js-air-food-fish posr h275@lgx h200@md h350 w100vw t0">
            <div className="js-wavy-text-1 posa w50% t0vh l40vw w90%@md z1 l0@md">
              <svg className="wavePut"
                viewBox={`${
                  window.innerWidth > 800 ? "-40 -60" : "-200 -60"
                } 800 450`}
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `#wavy-text-path-1 { min-height: 0vh; font-size: clamp(50px, 3vw, 56px); }`,
                    }}
                  ></style>
                  {window.innerWidth > 801 && (
                    <path
                      id="wavy-path-1"
                      d="M6.3,217.61c0-84.66,68.63-153.29,153.29-153.29c45.91,0,87.11,20.19,115.21,52.17l-0.08-0.08
			c28.07,31.37,68.86,51.11,114.27,51.11c84.66,0,153.29-68.63,153.29-153.29"
                    />
                  )}
                  {window.innerWidth <= 800 && (
                    <path
                      id="wavy-path-1"
                      d="M6.3,217.61c0-84.66,68.63-153.29,153.29-153.29c45.91,0,87.11,20.19,115.21,52.17l-0.08-0.08
			c28.07,31.37,68.86,51.11,114.27,51.11c84.66,0,153.29-68.63,153.29-153.29"
                    />
                  )}
                </defs>
                <text id="wavy-text" fill="#fff" letterSpacing=".3vw">
                  <textPath
                    id="wavy-text-path-1"
                    xlinkHref="#wavy-path-1"                    startOffset="0%"
                  >
                    We Make Meat From Air
                  </textPath>
                </text>
              </svg>
            </div>
            {/* <div className="js-wavy-text-2 posa w100% t60vh l62vw z1 t70vh@md l55vw@md w115%@md t65vh@sm">
              <svg viewBox="0 0 1000 500">
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `#wavy-text-path-2 { min-height: 0vh; font-size: clamp(48px, 4vw, 56px); }`,
                    }}
                  ></style>
                  {window.innerWidth > 800 && (
                    <path
                      id="wavy-path-2"
                      d="M51.5958252,55.3439026 C244.652283,81.9812622 342.720154,98.0216675 385.215698,314.5 C413.546061,458.818888 510.009644,525.529053 674.606445,514.630493"
                    />
                  )}
                  {window.innerWidth <= 800 && (
                    <path
                      id="wavy-path-2"
                      d="M13.55,253.08c51.22,20.22,114.57,6.74,169.83-22.91S308.73,46,308.73,46"
                    />
                  )}
                </defs>
              </svg>
            </div> */}
            {/* {(window.innerWidth > 800 || !mobileTabletCheck()) && (
              <video
                className='posa w100% l55% trf-trx(-50%)'
                muted={true}
                playsInline={true}
                preload='auto'
                ref={fishVideo}
              >
                <source
                  src='https://d172nc0scfm542.cloudfront.net/video/fish-hevc.mov'
                  type='video/quicktime'
                />
                <source
                  src='https://d172nc0scfm542.cloudfront.net/video/fish.webm'
                  type='video/webm'
                />
              </video>
            )}
            {(window.innerWidth <= 800 || mobileTabletCheck()) && (
              <img
                className='posa w40% t50% l43% trf-tr(-50%,-50%)'
                src='https://d172nc0scfm542.cloudfront.net/home/fish.png'
              />
            )} */}
          </div>
        </div>

        {
          //
          // Cloud cover section
          //
        }
        {/* <img
          className="js-cloud-overlay posa"
          src="https://d172nc0scfm542.cloudfront.net/home/cloud-overlay.png"
          style={{
            bottom: "-80vh",
            right: "-10vh",
            width: "150vh",
          }}
        /> */}
        {/* <div
          className="posa w100% b0 l0 h10vh z5"
          style={{
            background: "linear-gradient(#8BC8E200, #8BC8E2ff)",
          }}
        ></div> */}
      </div>
    </div>
  );
};

export default Section1;
