import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Grid from '../blocks/Grid';
import ButtonCTA from '../components/ButtonCTA';
import Facebook from '../components/SocialIcons/Facebook';
import Instagram from '../components/SocialIcons/Instagram';
import LinkedIn from '../components/SocialIcons/LinkedIn';
import Twitter from '../components/SocialIcons/Twitter';
import LogoWhite from '../components/LogoWhite'
import YouTube from '../components/SocialIcons/YouTube';

const Footer = () => {
	const { register, handleSubmit, formState, reset } = useForm();
	const { errors, isSubmitSuccessful, isSubmitted } = formState;
	const [submitError, setSubmitError] = useState(false)
	const [buttonLabel, setButtonLabel] = useState('GET OUR NEWSLETTER')
	const onSubmit = async data => {
		if (isSubmitted) return;
		try {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify(data);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
				cors: 'no-cors'
			};

			// MC_URL Set in env file 
			await fetch(MC_URL, requestOptions)
			reset()
			setButtonLabel('SUCCESS')
		} catch (error) {
			setSubmitError(true)
			setButtonLabel('ERROR')
			throw error
		}
	}

	return <footer className="footer bgc-crusta c-white posr z5">
		<Grid className="w90%@md">
			<div className="row fxdrc@md aic@md mb-items-md@md">
				<div className="col-3 col-12@md df fxdrc mr1rem">
					<div className="col-title lts1.72">Air Protein</div>
					<Link to="/our-process">Our Production</Link>
					<Link to="/air-protein">Our Protein</Link>
					<Link to="/our-story">Our Story</Link>
					{/* <Link to="/our-vision">Our Vision</Link> */}
					<Link to="/press-and-media">Press</Link>
					<Link to="/join-our-team">Join Our Team</Link>
					<Link to="/contact-us">Contact Us</Link>
					<Link to="/faq">FAQ</Link>
				</div>
				<div className="col-5 col-12@md df fxdrc jcsb jcc@md mr1rem">
					<div className="">
						<div className="col-title lts1.72 mb0.5rem">CONNECT WITH US</div>
						<div className="social mb1.5rem">
							<a className="pr0.5rem" href="https://www.facebook.com/airprotein">
								<Facebook />
							</a>
							<a className="pr0.5rem" href="https://www.instagram.com/airprotein/">
								<Instagram />
							</a>
							<a className="pr0.5rem" href="https://www.linkedin.com/company/air-protein">
								<LinkedIn />
							</a>
							<a className="pr0.5rem" href="https://www.youtube.com/channel/UC9521GYQpaqLYwwbi1ExmfA">
								<YouTube />
							</a>
						</div>
					</div>
					<div className="">
						<div className="col-title lts1.72">PRIVACY INFORMATION</div>
						<Link to="/privacy">Privacy Statements</Link><br></br>
						<Link to="/terms">Terms Of Use</Link>
					</div>
					<div className="db dn@md fz1rem mt3rem">
						&copy; {new Date().getFullYear()} Air Protein Inc. All Rights Reserved.
					</div>
				</div>

			</div>
			<div className="row fxdrc@md aic@md mb-items-md@md">
				<div className="col-8 col-12@md df fxdrc mr1rem">
				</div>
				<div className="col-3 col-12@md df fxdrc">
					<div className="logo-size">
						<Link to="/">
						<LogoWhite />
						</Link>
					</div>
				</div>
			</div>
			<div className="dn db@md fz1rem mt3rem">
				&copy; {new Date().getFullYear()} Air Protein Inc. All Rights Reserved.
			</div>
		</Grid>
	</footer>
}

export default Footer;
