import { gsap } from "gsap";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Grid from "../blocks/Grid";
import ButtonCTA from "../components/ButtonCTA";
import ManiVideo from "./About/ManiVideo";

const About = () => {
  useEffect(() => {
    let tl, tl2;
    setTimeout(() => {
      const trigger = ".moonshot";
      let delayObject = {
        idx: 0,
      };
      tl2 = gsap.timeline();
      tl2
        .to(".mc-copy div", {
          opacity: "100%",
        });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: trigger,
          start: "center center",
          end: "+=2500",
          scrub: 1,
          markers: false,
          pin: trigger,
        },
      });

      tl.to(".moon-window", {
        clipPath: "circle(25% at 50% 50%)",
      })
        .to(".moon-holder", {
          left: "40%",
        })
        .to(
          ".moon-title",
          {
            opacity: "100%",
          },
          "<"
        )
        .to(".moon-copy", {
          opacity: "100%",
        })
        .to(".moon-holder", {
          opacity: "0",
        })
        .add(tl2, "circleIn")
        .to(
          ".mc-icons",
          {
            opacity: "100%",
          },
          "circleIn"
        )
        .to(delayObject, {
          idx: 1000,
        })
        .to(delayObject, {
          idx: 0,
        });
    }, 250);
    return () => {
      try {
        tl.kill();
        tl2.kill();
      } catch (error) {}
    };
  }, []);
  return (
    <div className="page-about">
      <Helmet>
        <title>Air Protein | About Air Protein</title>
        <meta
          name="description"
          content="From moonshot to protein. Inspired by NASA, designed by Air Protein. Meet the team."
        ></meta>
        <meta
          name="keywords"
          content="Carbon negative food, meat alternative, air meat, plant based meat, Lisa Dyson, ADM"
        ></meta>
      </Helmet>


			<div className="w100% pl100 pr100 ml-auto mr-auto rev-wrap"
			style={{
				backgroundImage: 'url("https://d172nc0scfm542.cloudfront.net/home/bg1c.png")',
				backgroundSize: 'cover'
			}}
			>
			<div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section1 revolution home-intro">
        <div className="home-section1-half zindex1">
        <h1 className='home-intro-h1'>Functional, Nutritious and Affordable</h1>
        </div>
        <div className="home-section1-half zindex0">
				<img className="responsive home-intro-pasta" src="https://d172nc0scfm542.cloudfront.net/air-protein/compund-circles-with-flour-and-people.png" />
          </div>
</div>
		</div>





    <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section2">

    <div className="home-section1-half">
				<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/air-protein/Word-Cloud.jpg" />
          </div>
    <div className="home-section1-half rightSet">
        <h2>A Versatile Super Protein</h2>
        <p className="w55% introText">Delivering a range of functional and nutritional protein ingredients for categories across the food spectrum.</p>
        </div>


		</div>


		<div className='page-careers'>

		<Grid>
			<div className="quote-grid w100% mih100vh mb3rem@md mt3rem@md proof" >
				<div className="quote-1 mih55% df fxdrc jcsb">
					<div className="">
						<div className="q-name c-white fz3.25rem fz2rem@md posr">The Proof is in the Numbers</div>
            <div className="c-white fz2rem fz1.5rem@md mb3rem">
            <img className="responsive" src="https://d172nc0scfm542.cloudfront.net/air-protein/air-protein-table.jpg" />
                {/* <table className="statsTable">
                  <tr>
                    <td>Protein:</td>
                    <td>&#x3e;85%</td>
                  </tr>
                  <tr><td>Low in Fat/Lipids:</td><td>&#x3c;5%</td></tr>
                  <tr><td>Amino Acids:</td><td>all 20 including all 9 essential ones</td></tr>
                  <tr><td>Cholesterol</td><td>0</td></tr>
                  <tr><td>Carbon Footprint</td><td>&#x3c;0 Carbon*</td></tr>
                  <tr><td>High Digestibility</td><td>PDCAAS ~1</td></tr>
                </table>
                <footnote>*Cradle to Gate</footnote> */}
            </div>
					</div>
				</div>
				<div className="quote-2 mih55% df fxdrc jcsb">
					<div className="">
						<div className="q-name c-white fz3.25rem fz2rem@md posr">More nutritious</div>
            <div className="c-white fz2rem fz1.5rem@md mb3rem">Protein-dense, lower in fat and including all of the amino acids that the body needs, Air Protein provides a more nutritious solution for food development.</div>
					</div>
				</div>
			</div>
		</Grid>
</div>

<div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/home/bg1c.png)',
				backgroundSize: 'cover'
			}}
			>
        <div className="home-section1-half">
				<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/air-protein/amino-acid.png" />
          </div>
        <div className="home-section1-half tuneIn">
        <h2>The Building Blocks of Life</h2>
        <p className="w55% introText">Our bodies require amino acids to help digest food, fortify all tissues in the body, and supply energy. A whole and balanced complete protein, Air Protein contains all 20 amino acids, including the 9 essential amino acids.</p>
        </div>


		</div>


    {/* <div className="w100% pl100 pr100 ml-auto mr-auto full-height self-gras"
			style={{
				backgroundImage: 'url(https://d172nc0scfm542.cloudfront.net/air-protein/flour-hands.jpg)',
				backgroundSize: 'cover'
			}}
			>
    <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section2">

    <div className="home-section1-half">
          </div>
    <div className="home-section1-half rightSet wideSet">
        <h2>Achieved self-GRAS (generally recognized as safe) status</h2>
        <p className="w55% introText">A first for an AirFermentation<tm>TM</tm> -based ingredient
<ul className="bullPin grasPin">

<li>No pesticides or herbicides</li>
<li>Absence of anti-nutrients found in plant-based foods such as phytates, tannins, lectins, and oxalates</li>
<li>Non-GMO</li>
<li>No heavy metals</li>
</ul>
</p>
        </div>

</div>
		</div> */}



    <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 flavorBox">
        <div className="home-section1-half tuneIn">
        <h2>Novel Technology to Deliver Neutral Protein Ingredients</h2>
        <p className="w55% introText">Can Achieve Neutral Flavor, Aroma, and Color for Almost Any Food Application.</p>
        </div>
        <div className="home-section1-half">
				<img className="responsive" src="https://d172nc0scfm542.cloudfront.net/air-protein/girl-sniff.jpg" />
          </div>

		</div>

    {/* <div className="fuller">
<img className="responsive deskOnly" src="https://d172nc0scfm542.cloudfront.net/air-protein/zero-land.jpg" />
<img className="responsive mobOnly" src="https://d172nc0scfm542.cloudfront.net/air-protein/zero-land-mob.jpg" />
</div> */}



    </div>
  );
};

export default About;
