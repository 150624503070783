import React from "react"
import Grid from "../blocks/Grid"
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import LogoWhite from '../components/LogoWhite'
import './astro.scss';

const Privacy = _ => {
    return <div className="astro-page">
        <Helmet>
            <title>Air Protein | Dr. Lisa Dyson, PhD</title>
            <meta name="description" content=""></meta>
            <meta name="keywords" content=""></meta>
            <meta name="robots" content="nofollow"></meta>
            <meta name="robots" content="noindex"></meta>
        </Helmet>
        <Grid className="astro-grid">

        <div className="grid maw1400 posr w100% pl100 pr100 ml-auto mr-auto split-2 home-section2">

<div className="home-section1-half astro-image">
    <img className="responsive" src="https://d172nc0scfm542.cloudfront.net/astro/LISA.jpg" />
      </div>
<div className="home-section1-half rightSet c-white astro-text">
    <h2 className="c-white">Dr. Lisa Dyson, PhD</h2>
    <p className="w55% introText"><span className="bigger">Founder, CEO & Director</span><br></br>650-575-7522<br></br>
<a href="mailto:lisa.dyson@airprotein.com">lisa.dyson@airprotein.com</a></p>
<div className="astro-logo-size">
<Link to="/">
						<LogoWhite />
						</Link></div>
            <p className="w55% introText">
            <span className="bigger">2020 Williams St. B1<br></br>San Leandro, CA 94577</span><br></br><a href="https://airprotein.com">https://airprotein.com</a></p>
    </div>


</div>

        </Grid>
        </div>
}

export default Privacy