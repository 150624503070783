import React from 'react';

const color1 = '#f68739';
const color2 = '#fff';

export default function WhyAirProteinDisc({ className, style, invert }) {
	const c1 = invert ? color2 : color1;
	const c2 = invert ? color1 : color2;
	return (
	<div
		className={`why-air-protein-disc ${ className || '' }`}
		style={style}
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 182.98 171.46"
			className="why-air-protein-disc__svg animation-rotate-z"
		>
			<defs>
				<style dangerouslySetInnerHTML={{ __html : `.cls-1-${ invert }{fill:${ c1 };}.cls-2-${ invert }{font-size:14.94px;fill:${ c2 };font-family:EuclidCircularA-Light, EuclidCircularA Light;font-weight:300;}.cls-3-${ invert }{fill:none;stroke:${ c2 };stroke-miterlimit:10;stroke-width:0.93px;}` }}>
				</style>
			</defs>
			<circle className={`cls-1-${ invert }`} cx="91.57" cy="84.62" r="78.16"/>
			<text className={`cls-2-${ invert }`} transform="translate(48.26 116.67) rotate(-118.79)">W</text>
			<text className={`cls-2-${ invert }`} transform="translate(41.98 104.67) rotate(-105.61)">H</text>
			<text className={`cls-2-${ invert }`} transform="translate(39.27 94.37) rotate(-95)">Y</text>
			<text className={`cls-2-${ invert }`} transform="translate(38.7 85.13) rotate(-87.66)"> </text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.18, -0.98, 0.98, 0.18, 38.75, 80.8)">A</text>
			<text className={`cls-2-${ invert }`} transform="translate(40.83 70.78) rotate(-71.67)">I</text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.44, -0.9, 0.9, 0.44, 42.26, 66.26)">R</text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.55, -0.84, 0.84, 0.55, 46.5, 58.08)"> </text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.65, -0.76, 0.76, 0.65, 48.81, 54.42)">P</text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.77, -0.64, 0.64, 0.77, 54.75, 47.63)">R</text>
			<text className={`cls-2-${ invert }`} transform="matrix(0.88, -0.48, 0.48, 0.88, 61.74, 41.82)">O</text>
			<text className={`cls-2-${ invert }`} transform="translate(71.46 36.75) rotate(-18.17)">T</text>
			<text className={`cls-2-${ invert }`} transform="translate(79.58 34.18) rotate(-8.98)">E</text>
			<text className={`cls-2-${ invert }`} transform="translate(88 33.04) rotate(-1.84)">I</text>
			<text className={`cls-2-${ invert }`} transform="translate(92.73 32.74) rotate(6.62)">N</text>
			<text className={`cls-2-${ invert }`} transform="translate(134.71 54.78) rotate(61.21)">W</text>
			<text className={`cls-2-${ invert }`} transform="translate(141 66.79) rotate(74.39)">H</text>
			<text className={`cls-2-${ invert }`} transform="translate(143.71 77.08) rotate(85)">Y</text>
			<text className={`cls-2-${ invert }`} transform="translate(144.28 86.32) rotate(92.34)"> </text>
			<text className={`cls-2-${ invert }`} transform="matrix(-0.18, 0.98, -0.98, -0.18, 144.23, 90.65)">A</text>
			<text className={`cls-2-${ invert }`} transform="translate(142.14 100.67) rotate(108.33)">I</text>
			<text className={`cls-2-${ invert }`} transform="translate(140.72 105.2) rotate(115.94)">R</text>
			<text className={`cls-2-${ invert }`} transform="matrix(-0.55, 0.84, -0.84, -0.55, 136.48, 113.38)"> </text>
			<text className={`cls-2-${ invert }`} transform="matrix(-0.65, 0.76, -0.76, -0.65, 134.16, 117.03)">P</text>
			<text className={`cls-2-${ invert }`} transform="matrix(-0.77, 0.64, -0.64, -0.77, 128.23, 123.82)">R</text>
			<text className={`cls-2-${ invert }`} transform="matrix(-0.88, 0.48, -0.48, -0.88, 121.24, 129.63)">O</text>
			<text className={`cls-2-${ invert }`} transform="translate(111.52 134.71) rotate(161.83)">T</text>
			<text className={`cls-2-${ invert }`} transform="translate(103.4 137.28) rotate(171.02)">E</text>
			<text className={`cls-2-${ invert }`} transform="translate(94.97 138.41) rotate(178.16)">I</text>
			<text className={`cls-2-${ invert }`} transform="translate(90.25 138.71) rotate(-173.38)">N</text>
			<path className={`cls-3-${ invert }`} d="M116.58,36.22a57.67,57.67,0,0,0-11.19-7.54" transform="translate(12.49 5.73)"/>
			<path className={`cls-3-${ invert }`} d="M41.39,123.7a57.28,57.28,0,0,0,11.19,7.55" transform="translate(12.49 5.73)"/>
		</svg>
	</div>
	)
}