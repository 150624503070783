import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Grid from '../../../blocks/Grid';
import WhyAirProteinDisc from '../../../components/WhyAirProteinDisc';

const Section5 = () => {
  const ref = useRef(null);

  const playBloom = () => {
    const bloom = document.querySelector('.js-bloom-animation');
    if (bloom) {
      bloom.currentTime = 0;
      bloom.play();
    }
  };
  const pauseBloom = () => {
    const bloom = document.querySelector('.js-bloom-animation');
    if (bloom) bloom.pause();
  };

  const playResources = () => {
    const resourceVid = document.querySelector('.js-less-resources-video');
    if (resourceVid) {
      resourceVid.currentTime = 0;
      resourceVid.play();
    }
  };
  const pauseResources = () => {
    const resourceVid = document.querySelector('.js-less-resources-video');
    if (resourceVid) resourceVid.pause();
  };

  useEffect(() => {
    const element = ref.current;
    const animations = [];

    ScrollTrigger.matchMedia({
      '(min-width: 801px)': () => {
        animations.push(
          gsap
            .timeline()
            .to(element.querySelector('.js-section-5-disc'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-section-5-start'),
                start: `top ${window.innerHeight * 0.3}`,
                endTrigger: element.querySelector('.js-section-5-start'),
                end: `top top`,
                scrub: true,
                pin: true,
                pinSpacing: false,
                invalidateOnRefresh: true,
              },
              position: 'fixed',
              transform: 'translate(50%, -50%)',
            })
            .to(element.querySelector('.js-section-5-disc'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-section-5-start'),
                start: 'top top',
                pin: true,
                pinSpacing: false,
                scrub: true,
                endTrigger: element.querySelector('.js-section-5-end'),
                end: `bottom center`,
                invalidateOnRefresh: true,
              },
            })
            .to(element.querySelector('.js-section-5-disc'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-section-5-end'),
                start: `top bottom`,
                end: `top center`,
                scrub: true,
                pin: true,
                pinSpacing: false,
                invalidateOnRefresh: true,
              },
              right: '-50%',
            })
        );

        animations.push(
          gsap
            .timeline()
            .to(element.querySelector('.js-snap-section-1'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-1'),
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
                pin: true,
                pinSpacing: false,
                invalidateOnRefresh: true,
              },
            })
            .to(element.querySelector('.js-snap-section-1-content'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-1'),
                start: `-${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                end: `${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                scrub: true,
                invalidateOnRefresh: true,
              },
              opacity: '1',
              top: '0',
            })
            .to(element.querySelector('.js-snap-section-1-content'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-1'),
                start: `${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                end: `${window.innerHeight}px center`,
                scrub: true,
                pin: true,
                invalidateOnRefresh: true,
                onToggle: ({ isActive, direction }) => {
                  if (isActive) playBloom();
                  else if (direction === -1) pauseBloom();
                },
              },
            })
        );

        animations.push(
          gsap
            .timeline()
            .to(element.querySelector('.js-snap-section-2'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-2'),
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
                pin: true,
                pinSpacing: false,
                invalidateOnRefresh: true,
              },
            })
            .to(element.querySelector('.js-snap-section-2-content'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-2'),
                start: `-${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                end: `${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                scrub: true,
                invalidateOnRefresh: true,
              },
              opacity: '1',
              top: '0',
            })
            .to(element.querySelector('.js-snap-section-2-content'), {
              scrollTrigger: {
                trigger: element.querySelector('.js-snap-section-2'),
                start: `${window.innerHeight * 0.2}px ${
                  window.innerHeight * 0.2
                }px`,
                end: `${window.innerHeight}px top`,
                scrub: true,
                pin: true,
                invalidateOnRefresh: true,
                onToggle: ({ isActive, direction }) => {
                  if (isActive) {
                    pauseBloom();
                    playResources();
                  } else if (direction === -1) {
                    playBloom();
                    pauseResources();
                  } else pauseResources();
                },
              },
            })
        );
      },
    });

    return function cleanup() {
      animations.forEach((a) => {
        try {
          a.kill();
        } catch (error) {
          console.log(error);
        }
      });
    };
  }, []);

  return (
    <div className='section-5 posr z0' ref={ref}>
      <div className='js-section-5 posr z1 w100vw oh bgc-crusta c-white h445vh h-auto@md'>
        <div className='js-section-5-start posa t0'></div>
        <div className='js-section-5-end posa b0'></div>
        <WhyAirProteinDisc
          className='js-section-5-disc posa z0 r0 t50vh w13vw dn@md'
          style={{ transform: 'translate(100%, -50%)' }}
          invert={true}
        />
        <div className='js-snap-section-1 h100vh posr h-auto@md mb10vh@md'>
          <div className='js-snap-section-1-content posa h100vh op0 t80vh pt20vh h-auto@md op1@md posr@md t0@md pt10vh@md'>
            <Grid>
              <h2
                className='c-white z1 lh1 posr z1 h20vh mih0vh h-auto@md mb4vh@md'
                style={{ fontSize: 'clamp(32px, 5vw, 132px)' }}
              >
                Air Protein
                <br />
                Takes Less Time
              </h2>
              <div className='w100vw b0 l0 df aic jcsb h60vh fxdrcr@md jcc@md h-auto@md'>
                <div className='posr h100% w50% h-auto@md'>
                  {window.innerWidth > 800 && (
                    <video
                      className='js-bloom-animation posa mah70vh@md-h b0 trf-tr(-10%,30%) w160% posr@md'
                      preload='auto'
                      muted={true}
                      playsInline={true}
                    >
                      <source
                        src='https://d172nc0scfm542.cloudfront.net/video/less-time-hevc.mov'
                        type='video/quicktime'
                      />
                      <source
                        src='https://d172nc0scfm542.cloudfront.net/video/less-time.webm'
                        type='video/webm'
                      />
                    </video>
                  )}
                  {window.innerWidth <= 800 && (
                    <img
                      className='js-bloom-image b0 w150% posr center-hori w200%@sm'
                      src='https://d172nc0scfm542.cloudfront.net/home/flower-bloom.png'
                    />
                  )}
                </div>
                <div className='w40% c-plantation df aifs jcc fxdrc posr z1 w100%@md aifs@md h-auto@md mb10vh@md'>
                  <p className='fz1vw fz20@lgx w60% mb4vh w80%@md'>
                    Air Protein is ahead of its time. And with a process that
                    takes just few days to produce, it’s also ahead of
                    soy&rsquo;s time, chicken&rsquo;s time, and beef&rsquo;s
                    time.
                  </p>
                  <div className='w70% df fxww w100%@md'>
                    <div className='w50% c-white mb2vh'>
                      <div className='fz18'>Air Protein</div>
                      <div className='fz32 fz28@sm'>4 days</div>
                    </div>
                    <div className='w50% mb2vh'>
                      <div className='fz18'>Soy</div>
                      <div className='fz32 fz28@sm'>3 months</div>
                    </div>
                    <div className='w50%'>
                      <div className='fz18'>Chicken</div>
                      <div className='fz32 fz28@sm'>5 months</div>
                    </div>
                    <div className='w50%'>
                      <div className='fz18'>Beef</div>
                      <div className='fz32 fz28@sm'>2 years</div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </div>

        <div className='js-snap-section-2 h100vh posr t50vh t0@md h-auto@md mb10vh@md'>
          <div className='js-snap-section-2-content posa h100vh w100% op0 t180vh op1@md posr@md h-auto@md t0@md df@md fxdrcr@md'>
            <div className='posa t50% l50% trf-try(-50%) w45% c-plantation z1 l0@md t0@md trf-null@md w100%@md posr@md df@md fxdrc@md aic@md'>
              <h2
                className='c-white lh1 mb4vh mih0vh w60%@md w80%@sm'
                style={{
                  fontSize: 'clamp(32px, 5vw, 132px)',
                }}
              >
                Air Protein Takes
                <br />
                Less Resources
              </h2>
              <p className='fz1vw fz16@lgx pl4 w60% w60%@md w80%@sm'>
                Over half of the world&rsquo;s farmable land is currently used
                for agriculture. Air meats use 524,000x less land and 112,000x
                less water per kg than traditional meat production.
              </p>
            </div>
            {window.innerWidth > 800 && (
              <div className='posa b0 l0 h100vh posr@md h-auto@md'>
                <video
                  className='js-less-resources-video maw100vw h140vh posa center-vert l0 z0 posr@md h-auto@md w90vw@md trf-null!@md t0!@md'
                  muted={true}
                  playsInline={true}
                  preload='auto'
                  loop={true}
                >
                  <source
                    src='https://d172nc0scfm542.cloudfront.net/video/less-resources-hevc.mov'
                    type='video/quicktime'
                  />
                  <source
                    src='https://d172nc0scfm542.cloudfront.net/video/less-resources.webm'
                    type='video/webm'
                  />
                </video>
              </div>
            )}
          </div>
        </div>
        <div className='h25vh dn@md'></div>
      </div>
    </div>
  );
};

export default Section5;
