import React from 'react';

export default function NewsTileAlt({ className, news: { name, blurb, logo, thumbnail, articleLink, articleDate, logoTitle } }) {
	return (
		<a
			className={`news-tile curp tdn w306 h321 df fxdrc jcsb p25 mr50 bdrs25 bd3-s-transparent bd3-s-white:h bd3-s-white@md bd3-s-transparent h-auto@md ${className || ''}`}
			href={articleLink}
			target="_blank"
		>

			<div className="df jcsb aic">
					{thumbnail && thumbnail.url ? (
					<img className="news-tile__thumbnail h40 h28@lgx" src={thumbnail.url} />
					) : (
					<span>&nbsp;</span>
					)}
				</div>

			<p className="news-tile__description fz24 lh1.26 mb1.5rem">
				{name}
			</p>
			{blurb ? (
          <span className="blurb fz1.125rem w100%">{blurb}</span>
        ) : (
          <span>&nbsp;</span>
        )}
			<div className="df jcsb aic botSlot">
				{logo && logo.url
					? <img
						className="news-tile__logo h28"
						src={logo.url} />
					: <span>&nbsp;</span>
				}
				{(!logo || !logo.url) && logoTitle 
					? <span className="fz1.125rem w100%">{logoTitle}</span>
					:<span>&nbsp;</span>
				}
				<span className="news-tile__date fz14 c-white">
					{articleDate}
				</span>
			</div>
		</a>
	)
}